.page-register-questionnaire {

    .page-content {
        position: relative;
        padding-top: 0;
    }

    .opaque {
        opacity: 0.2;
        transition: opacity 0.3s ease-in;
        -ms-transition: opacity 0.3s ease-in;
        -moz-transition: opacity 0.3s ease-in;
        -webkit-transition: opacity 0.3s ease-in;

        transition: opacity 0.3s ease-out;
        -ms-transition: opacity 0.3s ease-out;
        -moz-transition: opacity 0.3s ease-out;
        -webkit-transition: opacity 0.3s ease-out;
    }

    .fluid-banner {
        
        min-height: 100vh;
        
        @include breakpoint(mobile) {
            padding-bottom: 0;
        }

        &__wrapper {
            width: 830px;
            display: block;
            z-index: 2;

            @include breakpoint(tablet) {
                margin-left: 30px;
                padding-top: 30px;
                width: calc(100vw - 330px);
            }

            @include breakpoint(mobile) {
                width: 100%;
                padding: 0 20px;
                background: $color--pale-blue;
            }
        }

        &__heading {
            text-align: left;
            margin-bottom: 0px;

            @include breakpoint(tablet) {
                @include font-size(35px, 35px);
            }

            @include breakpoint(tablet) {
                @include font-size(35px, 35px);
            }

            @include breakpoint(mobile) {
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &__detail {
            @include breakpoint(mobile) {
                background: $color--pale-blue;
                padding-bottom: 20px;
                padding-right: 0;
                padding-left: 0;
            }
        }

        &__desc {
            margin-top: 30px;
            margin-bottom: 0px;
            max-width: 750px;
            
            p {
                margin-top: 30px;
                margin-bottom: 35px;
                //font-weight: bold;
            }

            @include breakpoint(mobile) {
                color: $color--blue;
            }
        }

        .questions_prompt {
            display: none;
        }

        form {
            width: 100%;

            .toggle-radio-input input[type=radio]+label {
                border: 0px;
            }
        }

        .form-column {
            width: 355px;

            @include breakpoint(tablet) {
                width: 100%;
            }

            @include breakpoint(mobile) {
                width: 100%;
                padding-right: 0px;
            }

            &--left {
                width: 395px;
                padding-right: 40px;

                @include breakpoint(tablet) {
                    padding-right: 0;
                    width: 100%;
                }

                @include breakpoint(mobile) {
                    width: 100%;
                    padding-right: 0px;
                }
            }

            &--right {
                width: 435px;
                padding-left: 40px;
                padding-right: 40px;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 1px;
                    top: 0;
                    bottom: 20px;
                    left: 0;
                    background: $color--blue-2;

                    @include breakpoint(not-desktop) {
                        display: none;
                    }
                }

                @include breakpoint(not-desktop) {
                    border: none;
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0px;
                }
            }
        }

        .fieldWrapper {
            width: 100%;

            &.short-field {
                width: 48%;
            }

            &.Select {
                .tooltip-icon {
                    @include breakpoint(mobile) {
                        right: 50px;
                    }
                }
            }
        }



        .toggle-radio-input {
            
            margin-bottom: 40px;

            @include breakpoint(desktop) {
                width: 800px;
            }

            @include breakpoint(tablet) {
                flex-wrap: wrap;
            }

            input[type=radio] {

                & + label {
                
                    @include breakpoint(desktop) {
                        
                        padding: 20px 30px;
                        width: 360px;
                        line-height: 26px;
                        margin-right: 20px;
                    }

                    @include breakpoint(tablet) {
                        @include font-size(16px, 16px);
                        width: 100%;
                        margin-bottom: 10px;
                        padding: 15px 0;
                    }
                }
            }

            label:hover {
                background-color: $color-btn-purple--hover;
            }
        }



        .questionnaire {

            //margin-top: 200px;
            //margin-bottom: 400px; // TODO: make this half screen size minus footer (i.e. make sure the last question is visible in the middle of the screen)

            &__new {
                display: none;
            }

            &__existing {
                display: none;
            }

            .question {

                margin-top: 150px;
                margin-bottom: 150px;
                font-size: 18px;
                max-width: 800px;
                font-weight: 200;
                line-height: 22px;
                position: relative;

                &__title {
                    color: $color-label-text;
                    font-weight: 600;
                    margin-left: 0px;
                }

                &__detail {
                    color: $color-label-text;
                    font-weight: 500;
                    font-size: 14px;
                    padding-top: 10px;
                    margin-left: 0px;
                }

                &__responses {
                    color: $color-label-text;
                    font-size: 17px;
                    font-weight: 500;

                    padding-top: 20px;


                    display: inline-flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    -webkit-box-align: stretch;
                    align-items: stretch;
                    min-width: 200px;

                    .response {
                        
                        background-color: rgba(69, 56, 209, 0.08);
                        border-radius: 3px;
                        box-shadow: rgba(69, 56, 209, 0.6) 0px 0px 0px 1px inset;
                        display: inline-block;
                        margin-bottom: 8px;
                        height: 38px;
                        line-height: 37px;
                        padding: 0 40px 0 12px;
                        cursor: pointer;
                        position: relative;

                        &--textarea {
                            color: $color-label-text;
                            height: 80px;
                            line-height: 20px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            min-width: 400px;
                        }
                    }

                    input {
                        color: $color-label-text;
                    }

                    .response:hover {
                        background-color: rgba(69, 56, 209, 0.2);
                    }

                    .response.selected {
                        transition: all 0.1s ease-out 0s;
                        box-shadow: rgba(69, 56, 209, 0.8) 0px 0px 0px 2px inset;
                        background-color: rgba(69, 56, 209, 0.2);
                    }

                    .tick {
                        @extend %icon-tick;
                        display: inline-block;
                        font-size: 14px;
                        line-height: 34px;
                        position: absolute;
                        top: 0;
                        right: 10px;
                    }
                }

                &__continue {
                    margin-top: 23px;
                }

                &__ok {
                    display: none;
                    width: 75px;
                }

                &__next {
                    width: 75px;
                }
            }

            .other-container {
                position: relative;
            }

            .ok-btn-wrapper {
                height: 34px;
            }

            .loading-spinner {
                width: 25px;
                position: absolute;
                right: 11px;
                top: 50%;
                transform: translateY(-50%);

                @include breakpoint(mobile){
                    right: 35px;
                }

                &--hide {
                    display: none;
                }
            }

            .checkbox-input {
                margin-top: 20px;
                font-size: 14px;
                font-weight: 600;
            }

            .t-and-c__desc {
                font-size: 12px;
                margin-bottom: 0px;
            }
        }
    }

    .form-footer {
        padding: 38px 40px 0px 0;
        color: $color--blue;
        text-align: center;
        border-top: 2px solid $color--blue-4;

        @include breakpoint(tablet) {
            padding: 38px 0px 0px 0;
        }

        @include breakpoint(mobile) {
            padding: 28px 0px 28px 0;
            @include font-size(12px, 16px);
        }
    }

    .onboarding-journey-progress {
        left: $dark-bcg-start-point;
        margin-left: 50px;
        transform: translateZ(0);

        &.fixed-position {
            position: fixed;
            top: calc(10vh + 169.67px);
        }

        &.absolute-position {
            position: absolute;
            bottom: calc(127px);
        }

        @include breakpoint(tablet) {
            left: $dark-bcg-start-point-tb;
            top: 190px;
            margin-left: 30px;
            max-width: 180px;

            &-item-desc__text {
                display: none;
            }
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    #corporate-fund {
        .form-section__sub-title {
            display: block;
            margin: 104px 0;
        }
    }
}
