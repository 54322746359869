/*
==========================================================================
   #Page
========================================================================== */
html {
    @include font(montserrat, medium);
    @include font-size($base-font-size, $base-line-height);
    color: $base-text-color;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;


    @include breakpoint(mobile) {
        @include font-size($base-font-size-mb, $base-line-height-mb);
    }
}

body {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
}