$module: 'how-steps-banner';
.page-howitworks {
    .page-content {
        @include breakpoint(not-desktop) {
            overflow-x: hidden;
        }
    }
}

.#{$module} {
    @include breakpoint(tablet) {
        padding-bottom: 125px;
    }
    @include breakpoint(mobile) {
        padding-top: 45px;
    }

    .page-section__heading {
        text-align: center;
        margin-bottom: 105px;

        @include breakpoint(tablet) {
            font-size: 40px;
            margin-bottom: 75px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 48px;
        }
    }

    &__row {
        display: flex;
        flex-direction: column;
        counter-reset: section;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    &__col {
        display: flex;
        padding-left: 0;

        @include breakpoint(desktop) {
            align-items: center;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
        }

        &:nth-of-type(2n) {
            justify-content: flex-end;

            .how-steps-banner__details {
                margin-right: 20px;

                @include breakpoint(mobile) {
                    margin-right: 0;
                }
            }
        }

        &:nth-of-type(2n+1) {
            .how-steps-banner__details {
                margin-right: 60px;

                @include breakpoint(mobile) {
                    margin-right: 0;
                }
            }
        }

        &:first-of-type {
            margin-bottom: 75px;

            @include breakpoint(mobile) {
                margin-bottom: 60px;
            }

            .how-steps-banner__img {
                width: 215px;
                position: relative;

                @include breakpoint(mobile) {
                    width: 186px;
                }

                .how-steps-banner__arrow {
                    display: block;
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    background-image: url(../img/dashed-arrow-right.svg);
                    width: 172px;
                    height: 130px;
                    bottom: 0;
                    left: 100%;
                    margin-left: 72px;

                    @include breakpoint(tablet) {
                        left: 0;
                        margin-left: -19px;
                    }

                    @include breakpoint(tablet) {
                        top: 100%;
                    }

                    @include breakpoint(mobile) {
                        display: none;
                    }
                }
            }
        }

        &:nth-of-type(2) {
            margin-bottom: 65px;

            @include breakpoint(tablet) {
                margin-bottom: 85px;
            }

            @include breakpoint(mobile) {
                margin-bottom: 60px;
            }

            .how-steps-banner__img {
                width: 180px;

                @include breakpoint(mobile) {
                    width: 110px;
                }
            }

            .how-steps-banner__details {
                @include breakpoint(tablet) {
                    margin-top: 90px;
                }

                .how-steps-banner__arrow {
                    display: block;
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    background-image: url(../img/dashed-arrow-left.svg);
                    width: 172px;
                    height: 130px;
                    bottom: 0;
                    right: 100%;
                    margin-right: 138px;

                    @include breakpoint(tablet) {
                        margin-right: -45px;
                    }

                    @include breakpoint(mobile) {
                        display: none;
                    }
                }
            }
        }

        &:nth-of-type(3) {
            margin-bottom: 110px;

            @include breakpoint(tablet) {
                margin-bottom: 145px;
            }

            @include breakpoint(mobile) {
                margin-bottom: 60px;
            }

            .how-steps-banner__img {
                width: 172px;
                position: relative;

                @include breakpoint(mobile) {
                    width: 120px;
                }

                .how-steps-banner__arrow {
                    display: block;
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    background-image: url(../img/dashed-arrow-right.svg);
                    width: 172px;
                    height: 130px;
                    bottom: 0;
                    left: 100%;
                    margin-left: 72px;

                    @include breakpoint(tablet) {
                        top: calc(100% + 20px);
                        left: 0;
                        margin-left: -45px;
                    }

                    @include breakpoint(mobile) {
                        display: none;
                    }
                }
            }
        }

        &:nth-of-type(4) {
            margin-bottom: 140px;
            position: relative;

            @include breakpoint(tablet) {
                margin-bottom: 165px;
            }

            @include breakpoint(mobile) {
                margin-bottom: 40px;
            }

            .how-steps-banner__arrow {
                display: block;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                background-image: url(../img/dashed-arrow-down.svg);
                width: 30px;
                height: 87px;
                top: calc(100% + 12px);
                left: 50%;
                transform: translateX(-50%);

                @include breakpoint(tablet) {
                    top: calc(100% + 35px);
                }

                @include breakpoint(mobile) {
                    display: none;
                }
            }

            .how-steps-banner__img {
                width: 180px;

                @include breakpoint(mobile) {
                    width: 144px;
                }
            }

            .how-steps-banner__details {
                @include breakpoint(tablet) {
                    margin-top: 20px;
                }
            }
        }
    }

    &__details {
        width: 525px;
        counter-increment: section;
        position: relative;
        padding-left: 80px;

        @include breakpoint(tablet) {
            width: 50%;
        }

        @include breakpoint(mobile) {
            width: 100%;
            padding-left: 50px;
            margin-right: 0;
            margin-bottom: 25px;
        }

        &:nth-of-type(2n) {
            justify-content: flex-end;
        }

        .how-steps-banner__count {
            position: absolute;
            left: 0;
            align-self: flex-start;
            color: $color-white;
            @include circle(50px, $color--blue-2);
            @include font-size(26px, 50px);
            @include font(lora, bold);
            text-align: center;

            @include breakpoint(mobile) {
                width: 33px;
                height: 33px;
                @include font-size(22px, 33px);
            }
        }
    }

    &__subtitle {
        @include font-size(20px, 25px);
        font-weight: bold;
        color: $color--blue;
        margin-top: 0;
        margin-bottom: 26px;

        @include breakpoint(mobile) {
            @include font-size(16px, 24px);
            margin-bottom: 16px;
        }
    }

    &__desc {
        margin-bottom: 20px;

        @include breakpoint(mobile) {
            margin-bottom: 10px;
        }
    }

    &__img {
        @include breakpoint(tablet) {
            align-self: flex-start;
        }

        @include breakpoint(mobile) {
            align-self: center;
        }

        img {
            width: 100%;
        }
    }

    &__btn {
        text-align: center;
        .btn {
            min-width: 355px;

            @include breakpoint(mobile) {
                margin-bottom: 0;
                min-width: auto;
            }
        }
    }
}

.how-access-banner {
    background-image: url(../img/banners/earth.png);
    background-color: #e1eaf8;
    background-position: center bottom;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 85px;
    position: relative;

    @include breakpoint(desktop) {
        background-size: 1120px;
    }

    @include breakpoint(tablet) {
        padding-top: 110px;
        padding-bottom: 100px;
    }

    @include breakpoint(mobile) {
        padding-top: 60px;
        padding-bottom: 30px;
        background-image: url(../img/banners/earth-mb.png);
        background-size: 270px;
        background-position: center;
    }

    &:before {
        content: '';
        display: block;
        border-radius: 50%;
        background-image: linear-gradient(to top, #9d95e6 0, #fff 54%, #fff 100%);
        box-shadow: 1px 3px 117px 0 rgba(0, 0, 0, .22);
        opacity: .35;
        position: absolute;
        right: calc((100% - #{$wrapper-width}) / 2 + 100px);
        top: -35px;
        width: 362px;
        height: 362px;
        animation: float1 6s linear infinite;

        @include breakpoint(mobile) {
            width: 190px;
            height: 190px;
            right: -50px;
            top: -35px;
        }
    }

    &:after {
        content: '';
        display: block;
        border-radius: 50%;
        background-image: linear-gradient(to bottom, transparent 0%, transparent 70%, #9890e5 100%);
        opacity: .6;
        position: absolute;
        width: 453px;
        height: 453px;
        bottom: -80px;
        left: calc((100% - 1180px) / 2 - 180px);
        animation: float2 6s linear infinite;

        @include breakpoint(mobile) {
            width: 297px;
            height: 297px;
            bottom: -40px;
            left: -120px;
        }
    }

    .wrapper {
        position: relative;
        z-index: 1;
    }

    img {

        height: auto;

        @include breakpoint(not-mobile) {
            max-width: 670px;
        }

        @include breakpoint(desktop) {
            margin-bottom: 12px;
        }
    }

    .page-section__heading {
        color: $color--blue-2;
        margin-bottom: 30px;

        @include breakpoint(tablet) {
            font-size: 36px;
            line-height: 36px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 20px;
        }
    }

    &__desc {
        max-width: 880px;
        margin-right: auto;
        margin-left: auto;
    }
}

.how-simple-banner {
    padding-bottom: 135px;

    @include breakpoint(tablet) {
        padding-top: 115px;
        padding-bottom: 100px;
    }

    @include breakpoint(mobile) {
        padding-bottom: 50px;
    }

    &--dark {
        background-color: $color--blue-2;
        color: $color-white;

        .page-section__heading {
            color: $color--pale-blue;
        }

        .how-simple-banner__subtitle {
            color: $color-white;
        }
    }

    &--light {
        .page-section__heading {
            color: $color--violet-3;
        }

        .how-simple-banner__subtitle {
            color: $color--violet-3;
        }
    }

    .page-section__heading {
        text-align: center;
        margin-bottom: 110px;

        @include breakpoint(tablet) {
            font-size: 36px;
            margin-bottom: 40px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 25px;
        }
    }

    &__row {
        display: flex;

        @include breakpoint(not-desktop) {
            flex-direction: column;
        }
    }

    &__details {
        @include breakpoint(desktop) {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        @include breakpoint(mobile) {
            text-align: center;
        }
    }

    img {
        height: auto;
        object-fit: contain;

        @include breakpoint(desktop) {
            width: 538px;
            height: 362px;
            align-self: flex-start;
            margin-right: 98px;
        }

        @include breakpoint(not-desktop) {
            width: 100%;
            margin-right: 0;
            align-self: center;
        }

        @include breakpoint(tablet) {
            margin-bottom: 40px;
            height: 425px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 30px;
            height: 40vw;
            min-height: 200px;
        }
    }

    &__subtitle {
        @include font(montserrat, bold);
        font-size: 22px;
        margin-top: 0;
        margin-bottom: 5px;

        @include breakpoint(tablet) {
            margin-bottom: 23px;
        }

        @include breakpoint(mobile) {
            @include font(montserrat, medium);
            font-size: 16px;
            margin-bottom: 22px;
        }
    }

    .rich-text {
        margin-bottom: 17px;
    }

    .btn {
        margin-bottom: 0;
        margin-top: auto;
        align-self: flex-start;

        @include breakpoint(not-mobile) {
            min-width: 312px;
        }

        @include breakpoint(tablet) {
            margin-top: 15px;
        }

        @include breakpoint(mobile) {
            margin-top: 10px;
        }
    }
}
