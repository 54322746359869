.page-pricing {
    .page-content {
        padding-top: 0;
        overflow: hidden;
    }
}

.promo-banner {
    width: 100%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    overflow: hidden;

    &__body {
        padding:15px 0 25px 0;
        background-color: #ed5b5b;
        overflow: hidden;
        h1 {
            font-size: 50px;
            color: white;
        }

        p {
            font-size: 22px;
            color: white;
            margin: 5px;
            font-weight: 500;
        }

        .promo-conditions {
            margin-top: 20px;
            font-size: 15px;
        }

        &:before {
            content: '';
            display: block;
            border-radius: 50%;
            background-image: linear-gradient(to top, rgb(157, 149, 230) 0%, rgb(255, 255, 255) 54%, rgb(255, 255, 255) 100%);
            box-shadow: 1px 3px 117px 0 rgba(0, 0, 0, .22);
            opacity: 0.1;
            position: absolute;
            right: calc((100% - #{$wrapper-width}) / 2);
            top: -130px;
            width: 278px;
            height: 278px;
            animation: float1 16s ease-in-out infinite;

            @include breakpoint(tablet-portrait) {
                right: -55px;
                top: -110px;
            }

            @include breakpoint(mobile) {
                width: 135px;
                height: 135px;
                right: -45px;
                top: -45px;
                opacity: 0.11;
            }
        }

        &:after {
            content: "";
            display: block;
            border-radius: 50%;
            background-image: linear-gradient(to top, rgb(157, 149, 230) 0%, rgb(255, 255, 255) 54%, rgb(255, 255, 255) 100%);
            opacity: .08;
            position: absolute;
            bottom: 20px;
            width: 480px;
            height: 480px;
            left: calc((100% - 1180px) / 2);
            animation: float1 12s ease-in-out infinite;
            @include breakpoint(tablet) {
                width: 340px;
                height: 340px;
                left: -50px;
            }
            @include breakpoint(mobile) {
                opacity: 0.11;
            }
        }
    }
}

.pricing-banner {
    background-color: $color--alice-blue;
    color: $color--solitude-blue;
    margin-top: 105px;
    padding-bottom: 50px;

    @include breakpoint(tablet) {
        padding-top: 50px;
        margin-top: 120px;
        padding-bottom: 5px;
    }

    @include breakpoint(mobile) {
        padding-top: 55px;
        margin-top: 70px;
        padding-bottom: 5px;
    }

    .wrapper {
        @include breakpoint(mobile) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .page-section__heading {
        text-align: center;
        color: $color--violet-3;
        margin-bottom: 0px;
        font-size: 36px;

        @include breakpoint(tablet) {
            font-size: 28px;
            margin-bottom: 25px;
        }

        @include breakpoint(mobile) {
            font-size: 24px;
            margin-bottom: 15px;
        }
    }

    .page-section__desc {
        max-width: 1000px;
        text-align: center;
        margin-right: auto;
        margin-left: auto;

        color: $color--violet-3;

        @include breakpoint(not-mobile) {
            @include font-size(17px, 26px);
            margin-top: 35px;
            margin-bottom: 35px;
        }

        @include breakpoint(tablet) {
            @include font-size(15px, 22px);
            max-width: 550px;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        @include breakpoint(mobile) {
            @include font-size(14px, 22px);
            margin-top: 30px;
            margin-bottom: 30px;
            max-width: 500px;
        }
    }

    &__cards {
        display: flex;
        flex-flow: row wrap;
        box-sizing: border-box;
        text-align: center;

        @include breakpoint(tablet) {
            display: block;
        }

        @include breakpoint(mobile) {
            display: block;
        }

        .or-text {

            @include breakpoint(desktop) {
                padding-top: 255px;
            }

            h3 {
                font-size: 18px;
                padding-bottom: 6px;
            }
        }
    }

    &__single-card {
        padding: 3rem 0;
        flex-flow: row wrap;
        margin: 15px;
        overflow: hidden;
        transition-property: color,background-color,box-shadow,transform;
        transition-duration: .15s;
        color: #3e4543;
        background: $color--white;
        border-radius: 0px;
        box-shadow: 0 -1px 5px rgba(0,0,0,0.05), 0 2px 5px rgba(0,0,0,0.07), 0px 5px 10px rgba(16,70,81,0.2);

        @include breakpoint(desktop) {
            flex: 1;
            max-width: 1150px;
        }

        @include breakpoint(tablet) {
            margin: 15px auto;
            text-align: center;
            overflow: hidden;
            max-width: 560px;
        }

        @include breakpoint(mobile) {
            margin: 15px auto;
            text-align: center;
            overflow: hidden;
            max-width: 560px;
        }

        h4 {
            margin-bottom: 30px;
            margin-top: 30px;
            font-weight: 600;
            font-size: 16px;
            color: #3e4543;

            @include breakpoint(tablet) {
                font-size: 15px;
                margin-top: 25px;
            }

            @include breakpoint(mobile) {
                font-size: 14px;
                margin-top: 20px;
            }
        }

        ul {
            margin-bottom: 10px;

            @include breakpoint(tablet) {
                margin-bottom: 25px;
            }

            @include breakpoint(mobile) {

            }
        }

        li {
            @include font-size(13px, 18px);
            text-align: left;
            font-weight: 200;
            list-style-type: none;
            list-style-position: outside;
            display: list-item;
            margin-bottom: 7px;
            text-indent: -12px;

            &:before {
                color: $color--light-green;
                content: "\2714";
                position: relative;
                left: -8px;
                font-size: 16px;
            }

            @include breakpoint(tablet) {
                @include font-size(12px, 15px);
            }

            @include breakpoint(mobile) {
                @include font-size(11px, 14px);
            }
        }
    }

    &__card {
        padding: 3rem;
        flex-flow: row wrap;
        margin: 15px;
        max-width: 560px;
        overflow: hidden;
        transition-property: color,background-color,box-shadow,transform;
        transition-duration: .15s;
        color: #3e4543;
        background: $color--white;
        border-radius: 0px;
        box-shadow: 0 -1px 5px rgba(0,0,0,0.05), 0 2px 5px rgba(0,0,0,0.07), 0px 5px 10px rgba(16,70,81,0.2);


        @include breakpoint(desktop) {
            flex: 1;
        }

        @include breakpoint(tablet) {
            margin: 15px auto;
            text-align: center;
            overflow: hidden;
        }

        @include breakpoint(mobile) {
            margin: 15px auto;
            text-align: center;
            overflow: hidden;
        }

        h4 {
            margin-bottom: 30px;
            margin-top: 30px;
            font-weight: 600;
            font-size: 16px;
            color: #3e4543;

            @include breakpoint(tablet) {
                font-size: 15px;
                margin-top: 25px;
            }

            @include breakpoint(mobile) {
                font-size: 14px;
                margin-top: 20px;
            }
        }

        ul {
            margin-bottom: 10px;

            @include breakpoint(tablet) {
                margin-bottom: 25px;
            }

            @include breakpoint(mobile) {

            }
        }

        li {
            @include font-size(13px, 18px);
            text-align: left;
            font-weight: 200;
            list-style-type: none;
            list-style-position: outside;
            display: list-item;
            margin-bottom: 7px;
            text-indent: -12px;

            &:before {
                color: $color--light-green;
                content: "\2714";
                position: relative;
                left: -8px;
                font-size: 16px;
            }

            @include breakpoint(tablet) {
                @include font-size(12px, 15px);
            }

            @include breakpoint(mobile) {
                @include font-size(11px, 14px);
            }
        }
    }

    .ongoing-split-lists {
        display: flex;
        flex-flow: row wrap;
        box-sizing: border-box;
        text-align: center;       

        @include breakpoint(mobile) {
            display: block;
        }
    }

    .ongoing-split-list {
        flex-flow: row wrap;
        padding-left: 49px;
        padding-right: 61px;
        overflow: hidden;
    }

    .ongoing-info {
        
        @include font-size(14px, 22px);
        font-weight: 400;
        padding: 0 40px;
        text-align: center;

        @include breakpoint(tablet) {
            @include font-size(13px, 18px);
        }

        @include breakpoint(mobile) {
            @include font-size(12px, 18px);
        }
    }

    .other-panels {
        @include font-size(13px, 18px);
        display: flex;
        flex-flow: row wrap;
        box-sizing: border-box;
        margin-top: 35px;

        @include breakpoint(tablet) {
            display: block;
        }

        @include breakpoint(mobile) {
            display: block;
        }
    }

    .other-panel {

        flex-flow: row wrap;
        padding-left: 40px;
        padding-right: 40px;
        overflow: hidden;
        max-width: 560px;
        text-align: center;
        
        &__desc {
            font-weight: 200;
            margin-top: 30px;
            text-align: left;
        }

        @include breakpoint(desktop) {
            max-width: 383px;
        }

        li {
            &:before {
                content: "-";
                font-weight: bold;
            }
        }
    }

    .other-panel:nth-of-type(2) {
        @include breakpoint(desktop) {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }

        @include breakpoint(tablet) {
            padding: 20px 0 40px 0;
            margin: 30px 40px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        @include breakpoint(mobile) {
            padding: 20px 0 40px 0;
            margin: 30px 40px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .other-details {
        text-align: left;
    }

    .pricing-banner__single-card ul {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .pricing-divider {

        margin: 30px auto;
        max-width: 500px;


        @include breakpoint(desktop) {
            margin: 18px auto;
            max-width: 1100px;
        }
        
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__title {

        @include font-size(20px, 22px);
        @include font(montserrat, bold);

        margin-top:10px;
        margin-bottom: 30px;
        align-self: center;

        @include breakpoint(tablet) {
            font-size: 18px;
            margin-bottom: 25px;
        }

        @include breakpoint(mobile) {
            font-size: 18px;
            margin-bottom: 25px;
        }
    }

    &__footer {
        @include font-size(15px, 22px);
        font-weight: 600;
        margin-top: 20px;
        padding-bottom: 0px;
        text-align: center;

        @include breakpoint(tablet) {
            @include font-size(12px, 18px);
        }

        @include breakpoint(mobile) {
            @include font-size(12px, 18px);
        }
    }

    &__info {
        @include font-size(14px, 22px);
        font-weight: 400;
        padding-top: 0px;
        padding-bottom: 0px;
        text-align: center;

        @include breakpoint(tablet) {
            @include font-size(13px, 18px);
        }

        @include breakpoint(mobile) {
            @include font-size(12px, 18px);
        }
    }

    &__condition {

        font-size: 12px;
        font-weight: 200;
        margin-top:0px;
        margin-bottom: 30px;

        @include breakpoint(tablet) {
            font-size: 10px;
            margin-bottom: 25px;
        }

        @include breakpoint(mobile) {
            font-size: 10px;
            margin-bottom: 25px;
        }
    }

    .page-section__footer {

        text-align: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: 55px;
        margin-bottom: 0px;
        color: $color--violet-3;

        @include breakpoint(not-mobile) {
            @include font-size(20px, 29px);
        }

        @include breakpoint(tablet) {
            @include font-size(16px, 24px);
            max-width: 600px;
            margin-bottom: 30px;
        }

        @include breakpoint(mobile) {
            @include font-size(16px, 24px);
            max-width: 500px;
            margin-bottom: 30px;
            margin-top: 50px;
        }

        h3 {
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                @include font-size(20px, 24px);
            }
            
            @include breakpoint(mobile) {
                margin-bottom: 10px;
                @include font-size(18px, 24px);
            }
        }

        .btn {
            margin-top: 10px;
        }
    }
}

