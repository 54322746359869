.page--form {
    .page-content {
            padding-top: 0;
    }

    .fluid-banner {
        @include breakpoint(tablet) {
            padding-top: 160px;
            padding-bottom: 80px;
        }

        &__wrapper {
            @include breakpoint(desktop) {
                flex-wrap: wrap;
            }

            @include breakpoint(tablet) {
                flex-direction: column;
                margin-right: $wrapper-padding-tb;
            }

            @include breakpoint(mobile) {
                display: flex;
                flex-direction: column;
            }
        }

        &__heading {
            @include font-size(50px, 59px);
            margin-bottom: 5px;

            @include breakpoint(desktop) {
                width: 100%;
            }

            @include breakpoint(tablet) {
                @include font-size(40px, 48px);
            }

            @include breakpoint(mobile) {
                background-color: $color--pale-blue;
                @include font-size(28px, 34px);
                margin-bottom: 0;
                padding-bottom: 20px;
                padding-left: $wrapper-padding-mb;
                padding-right: $wrapper-padding-mb;
            }
        }

        &__detail {
            @include breakpoint(desktop) {
                flex: 0 0 537px;
            }

            @include breakpoint(tablet) {
                width: 430px;
                flex: 1;
            }

            @include breakpoint(mobile) {
                order: 3;
                background-color: $color--blue-2;
            }
        }

        &__desc {
            @include font-size(18px, 26px);

            @include breakpoint(tablet) {
                margin-bottom: 26px;
            }

            @include breakpoint(mobile) {
                color: $color--white;
                @include font-size(15px, 21px);
            }
        }

        &__img {
            text-align: center;

            @include breakpoint(desktop) {
                flex: 1 0 0;
                margin-top: -45px;
                text-align: left;
            }

            @include breakpoint(tablet) {
                margin-top: 40px;
                margin-right: 40px;
                margin-left: 40px;
            }

            @include breakpoint(mobile) {
                padding-right: $wrapper-padding-mb;
                padding-left: $wrapper-padding-mb;
                position: relative;
                margin-bottom: 10px;
            }

            &:before {
                @include breakpoint(mobile) {
                    content: '';
                    background-color: $color--pale-blue;
                    display: block;
                    width: 100%;
                    height: 56%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    z-index: 1;
                }

            }

            img {
                @include breakpoint(not-mobile) {
                    width: 100%;
                    max-width: 650px;
                }

                @include breakpoint(mobile) {
                    z-index: 1;
                    position: relative;
                }
            }
        }

        form {
            @include breakpoint(not-mobile) {
                width: 428px;
            }

            // Custom breakpoint in-between tablet and desktop
            @media screen and (min-width: 768px) and (max-width: 930px) {
                width: 373px;
            }
        }

        .btn {
            @include breakpoint(not-mobile) {
                min-width: 250px;
            }

            @include breakpoint(mobile) {
                margin-bottom: 0;

                &.btn--mb-aqua {
                    background-color: $color-btn-aqua;
                    color: $color--free-speech-blue;

                    &:hover {
                        background-color: $color-btn-aqua--hover;
                        color: $color--white;
                    }
                }
            }
        }
    }
}
