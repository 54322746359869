.toggle-radio-input {
    display: flex;
    position: relative;

    @include breakpoint(desktop) {
        width: 550px;
    }

    @include breakpoint(mobile) {
        flex-wrap: wrap;
    }

    input[type=radio] {
        position: absolute;
        z-index: -1;
        opacity: 0;

        & + label {
            display: inline-block;
            @include font-size(18px, 18px);
            font-weight: bold;
            background-color: $color--ice-blue;
            color: $color--white;
            text-align: center;
            padding: 20px 0;
            width: 275px;
            transition: all $transition-time ease-in-out;

            &:before {
                display: none;
            }

            @include breakpoint(mobile) {
                @include font-size(14px, 14px);
                width: 100%;
                margin-bottom: 10px;
                padding: 15px 0;
            }
        }

        &:checked + label {
            background-color: $color--blue;
        }

        &:focus {
            + label {
                box-sizing: border-box;
                border: 1px solid $color-textinput-focus-shadow;
            }
        }
    }
}
