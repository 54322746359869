.page-register {
    .page-content {
        @include breakpoint(mobile) {
            flex: 1 0 0;
            background-color: $color--pale-blue;
        }
    }

    .fluid-banner {
        @include breakpoint(desktop) {
            padding-bottom: 30px;
        }

        @include breakpoint(tablet) {
            padding-bottom: 0;
        }

        &__img {

            @include breakpoint(desktop) {
                margin-top: -100px;
                text-align: right;
                width: calc(100% - 575px);
            }

            @include breakpoint(mobile) {
                display: none;
            }

            img {
                max-width: 100%;

                @include breakpoint(desktop) {
                    max-width: none;
                }
            }
        }

        &__detail {
            @include breakpoint(desktop) {
                flex: 0 0 575px;
            }

            @include breakpoint(mobile) {
                background-color: $color--pale-blue;
            }
        }

        &__heading {
            @include breakpoint(mobile) {
                padding-bottom: 7px;
            }
        }

        &__desc {
            @include breakpoint(mobile) {
                color: $color--blue-2;
            }
        }

        &__privacy {
            font-size: 13px;
            font-weight: bold;

            @include breakpoint(mobile) {
                margin-top: 27px;
                font-size: 12px;
            }
        }

        &__note {
            font-size: 13px;

            @include breakpoint(mobile) {
                margin-top: 27px;
                font-size: 12px;
            }

            a {
                color: inherit;

                &:hover {
                    color: $color-link-hover;
                }
            }
        }
    }
}

