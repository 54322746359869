.block-image_banner_bock {
    margin-bottom: 60px;

    @include breakpoint (mobile) {
        margin-bottom: 10px;
    }

    .banner {
        width: 100%;
    }

    img {
        width: 100%;
    }
}
