ul.tick {
    list-style: none;
    padding-left: 0;

    li {
        position: relative;
        padding-left: 35px;

        &:before {
            @extend %icon, %icon-tick;
            position: absolute;
            left: 0;
            font-size: 13px;
        }
    }
}