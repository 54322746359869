.page-register-check-your-email {
    .fluid-banner__detail {
        @include breakpoint(tablet) {
            width: calc(100vw - 360px);
        }
    }

    .start-your-journey-heading {
        font-weight: bold;
        margin-top: 70px;
        margin-bottom: 42px;

        @include breakpoint(tablet) {
            margin-top: 43px;
            margin-bottom: 30px;
        }

        @include breakpoint(mobile) {
            margin-top: 25px;
            margin-bottom: 30px;
        }
    }

    .onboarding-journey-progress {
        @include breakpoint(desktop) {
            position: absolute;
        }

        @include breakpoint(mobile) {
            @include onboarding-journey-progress-vertical();
        }
    }
}
