/*
==========================================================================
   #Navigation
========================================================================== */
$module: 'main-nav';
$main-menu-link-padding-h: 15px;

.#{$module} {
    @include breakpoint(desktop) {
        margin-left: auto;
    }

    @include breakpoint(not-desktop) {
        position: absolute;
        overflow: hidden;
        visibility: hidden;
        transition: visibility 0.5s, transform 0.5s;
        z-index: z-index(nav);

        .main-nav-open & {
            visibility: visible;
        }
    }

    @include breakpoint(tablet) {
        width: 340px;
        height: 100vh;
        right: 0;
        top: 0;
        padding-top: 105px;
        background-color: $color--pale-blue;
        transform: translateX(100%);

        .main-nav-open & {
            transform: translateX(0);
        }
    }

    @include breakpoint(mobile) {
        width: 100%;
        top: 100%;
        left: 0;
        transform: translateY(-100%);
        height: calc(100vh - #{$header-height-mb});
        overflow-y: auto;

        .main-nav-open & {
            transform: translateY(0);
        }
    }

    &__list {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        @include breakpoint(tablet) {
            background-color: $color--blue-2;
            height: 100%;
            overflow-y: auto;
        }
    }

    &__item {
        padding-bottom: 0;
        margin-bottom: 0;
        padding-left: 0;

        @include breakpoint(desktop) {
            display: inline-flex;
        }

        @include breakpoint(not-desktop) {
            display: block;
        }

        @include breakpoint(mobile) {
            display: block;
        }

        &--signin {
            @include breakpoint(desktop) {
                margin-left: 16px;
                margin-right: 16px;
                .main-nav__link {
                    color: $color--white;

                    &:before {
                        background-color: $color--white;
                    }

                    &:hover {
                        color: $color--white;
                    }
                }
            }
        }

        &--signout {
            @include breakpoint(desktop) {
                margin-left: 0px;
                margin-right: 0px;
                .main-nav__link {
                    color: $color--white;

                    &:before {
                        background-color: $color--white;
                    }

                    &:hover {
                        color: $color--white;
                    }
                }
            }
        }

        &--last {
            @include breakpoint(desktop) {
                margin-left: 0;
                min-width: 165px;
                justify-content: flex-end;
            }

            .#{$module}__link {

                @include breakpoint(desktop) {
                    border-radius: 2px;
                    padding: 13px 0px 13px 0px;
                    background: $color--white;
                    color: $color--blue;
                    width: 100%;
                    text-align: center;
                }

                @include breakpoint(not-desktop) {
                    background: $color--blue;
                    color: $color--white;
                }

                @include breakpoint(tablet) {
                    border-bottom: 2px solid $color--violet-2;
                }

                &:hover {
                    @include breakpoint(not-desktop) {
                        background-color: $color--dark-violet;
                        color: $color--white;
                    }

                    @include breakpoint(desktop) {
                        &:after {
                            transform: translateX(5px);
                            opacity: 1;
                            transition: all $transition-time;
                        }
                    }
                }
                &:before {
                    display: none;
                }

                &:after {
                    @include breakpoint(desktop) {
                        opacity: 0;
                        display: inline-block;
                        padding-left: 5px;
                        font-size: 12px;
                        vertical-align: top;
                        line-height: 22px;
                        text-decoration: none;
                        transform: translateX(0);
                        transition: all $transition-time;
                    }
                }
            }

            &.active {
                .#{$module}__link {
                    @include breakpoint(not-desktop) {
                        background-color: $color--dark-violet
                    }

                    @include breakpoint(desktop) {
                        text-decoration: none;

                        &:after {
                            transform: translateX(5px);
                            opacity: 1;
                        }
                    }

                }
            }

            &.hide-register-dashoard {
                visibility: hidden;
            }
        }

        &--dashboard {

            @include breakpoint(desktop) {
                margin-left: 0px;
            }
        }
    }

    &__link {
        text-decoration: none;
        @include font(montserrat, bold);
        text-transform: uppercase;
        color: $color--main-menu-text;
        position: relative;
        padding-left: 5px;
        padding-right: 18px;
        will-change: transform;

        @include breakpoint(not-desktop) {
            display: block;
            background: $color--pale-blue;
            border-top: 2px solid #9fbceb;
        }

        @include breakpoint(tablet) {
            padding: 20px 45px 20px 20px;
        }

        @include breakpoint(mobile) {
            padding: 13px calc(#{$wrapper-padding-mb} + 15px) 13px $wrapper-padding-mb;
        }

        &:before {
            @include breakpoint(desktop) {
                content: '';
                display: block;
                position: absolute;
                top: 100%;
                left: 5px;
                right: 18px;
                transform: scaleX(0);
                height: 4px;
                background-color: $color--main-menu-text;
                transition: transform $transition-time;
            }

        }

        &:hover, &:focus {
            text-decoration: none;
            color: $color--main-menu-text
        }

        &:hover, &:focus, .main-nav__item.active & {
            @include breakpoint(not-desktop) {
                background: darken($color--pale-blue, 10%);
            }

            &:before {
                transform: scaleX(1);
            }
        }

        .main-nav__item--last &:hover, .main-nav__item--last &:focus, .main-nav__item--last.active & {
            @include breakpoint(not-desktop) {
                background: $color--dark-violet;
            }
        }

        @include icon(arrow-right, after);

        &:after {
            @include breakpoint(desktop) {
                display: none;
            }

            @include breakpoint(not-desktop) {
                position: absolute;
            }

            @include breakpoint(tablet) {
                right: $wrapper-padding-tb;
            }

            @include breakpoint(mobile) {
                right: $wrapper-padding-mb;
            }
        }
    }

    &.style-signin {

        @include breakpoint(desktop) {

            .main-nav__item {

                &:nth-last-child(2) {

                    .main-nav__link {
                        color: $color--blue;

                        &:before {
                            background-color: $color--blue;
                        }
                    }
                }

                &:last-of-type {

                    .main-nav__link {
                        background: $color--blue;
                        color: $color--white;
                    }
                }
            }
        }
    }
}

.main-nav-open {
    .site-header__inner {
        &:after {
            width: 100%;
            height: 100%;
            opacity: 1;
            transition: opacity .5s;
        }
    }
}


