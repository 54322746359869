.page-content {
    padding-top: $header-height-dk;
    min-height: calc(100vh - 398px);
    @include breakpoint(tablet) {
        min-height: calc(100vh - 362px);
        padding-top: $header-height-tb;
    }

    @include breakpoint(mobile) {
        min-height: calc(100vh - 362px);
        padding-top: $header-height-mb;
    }
}
