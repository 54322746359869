.errorlist, .hintList {
    padding-left: 0;
    margin: 0;
    list-style: none;
    font-size: 10px;
    line-height: normal;

    @include breakpoint(mobile) {
        .mb-dark-bcg & {
            color: $color-white;
        }
    }

    li {
        margin-bottom: 0;
        padding: 3.5px 0;
    }
}

.errorlist {
    color: $color-error-text;
}

.hintList {
    color: $color--blue-2;
}

.fieldWrapper {
    position: relative;

    &.RadioSelect > label,
    &.CheckboxSelectMultiple > label {
        display: block;
        font-weight: bold;
        margin-top: 25px;
    }
}

.fieldErrorWrapper {
    min-height: 20px;

    @include breakpoint(mobile) {
        min-height: 10px;
        padding-bottom: 3px;
    }
}

.input-options {
    list-style: none;
    padding: 0;
    margin: 20px 0;

    li {
        display: flex;
        margin: 15px 0;
    }
}

.fieldInputWrapper {
    position: relative;

    label {
        position: absolute;
        left: $input-padding-hr;
        top: 15px;
        transition: all $transition-time;
        pointer-events: none;

        @include breakpoint(mobile) {
            top: 12px;
            left: $input-padding-hr-mb;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-animation: autofill 0s forwards;
        animation: autofill 0s forwards;
    }

    @keyframes autofill {
        100% {
            background: $color-white;
            color: inherit;
        }
    }

    @-webkit-keyframes autofill {
        100% {
            background: $color-white;
        }
    }


    input:focus ~ label,
    input:not(:empty) ~ label,
    textarea:focus ~ label,
    textarea:not(:empty) ~ label,
    select:focus ~ label,
    label.active
    {
        font-size: 10px;
        line-height: 10px;
        top: 10px;

        @include breakpoint(mobile) {
            top: 8px;
        }
    }

    input:-webkit-autofill ~ label {
        font-size: 10px;
        line-height: 10px;
        top: 10px;

        @include breakpoint(mobile) {
            top: 8px;
        }
    }

    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="url"],
    input[type="text"],
    input[type="search"],
    textarea,
    select {
        &.invalid {
            border: 2px solid $color-error-text;
        }

        &.has-helptext {
            @include breakpoint(mobile) {
                // Space for tooltip
                padding-right: 40px;
            }
        }
    }

    .tooltip-icon {
        position: absolute;
        margin-left: 5px;
        padding: 5px;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint(mobile) {
            left: auto;
            margin-left: 0;
            right: 7px;

        }
    }

    .tooltip {
        @include breakpoint(tablet) {
            max-width: 280px;
        }
    }
}

.grecaptcha-badge {
    z-index: 10;
}
