.page-review {
    .page-content {
        position: relative;
        padding-top: 0;
    }

    .errorlist {
        li {
            font-size: 12px;
            margin: 8px 0 8px 0;
        }
    }

    .fluid-banner {
        padding-bottom: 80px;

        @include breakpoint(tablet) {
            padding-bottom: 60px;
        }

        @include breakpoint(mobile) {
            padding-bottom: 40px;
        }

        &__wrapper {
            width: 820px;
            display: block;

            @include breakpoint(tablet) {
                margin-left: 20px;
                padding-top: 30px;
            }

            @include breakpoint(mobile) {
                width: 100%;
                background: $color--pale-blue;
            }
        }

        &__heading {
            text-align: left;
            width: 520px;

            @include breakpoint(tablet) {
                width: 360px;
                @include font-size(35px, 40px);
                padding-left: 10px;
            }

            @include breakpoint(mobile) {
                padding-bottom: 0;
                width: 85%;
            }
        }

        &__detail {
            padding-bottom: 0;

            @include breakpoint(tablet) {
                width: calc(100vw - 310px);
            }

            @include breakpoint(mobile) {
                background: $color--pale-blue;
            }
        }

        &__desc {
            margin-top: 26px;
            margin-bottom: 35px;
            width: 600px;

            @include breakpoint(tablet) {
                width: 100%;
                margin: 20px 0;
                padding-left: 10px;
                @include font-size(16px, 22px);
            }

            @include breakpoint(mobile) {
                margin: 20px 0;
                color: $color--blue;
                width: 100%;
                @include font-size(15px, 21px);
            }
        }

        .view-sample-link {
            @include font-size(16px, 16px);
            font-weight: bold;
            text-align: right;
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                padding-left: 10px;
                @include font-size(14px, 14px);
                text-align: left;
            }

            @include breakpoint(mobile) {
                width: 100%;
                text-align: left;
                @include font-size(14px, 14px);
            }
        }

        .application-review {
            background: $color--white;
            padding: 40px;
            box-shadow: 3.019px 2.624px 0px 0px rgba(0, 0, 0, 0.04);
            border-radius: 2px;

            @include breakpoint(not-desktop) {
                padding: 30px 20px;
            }

            .review-block {
                margin-bottom: 50px;
                width: 48%;

                @include breakpoint(desktop) {
                    &--2 {
                        .review-block__tile {
                            padding-bottom: 45px;
                        }
                    }
                }

                @include breakpoint(tablet) {
                    width: 100%;
                    margin-bottom: 30px;
                }

                @include breakpoint(mobile) {
                    width: 100%;
                    margin-bottom: 23px;
                }

                &__tile {
                    @include font-size(15px, 15px);
                    font-weight: bold;
                    color: $color--blue-2;
                    padding-bottom: 22px;
                    margin-bottom: 30px;
                    border-bottom: 1px solid $color--blue-2;

                    @include breakpoint(mobile) {
                        @include font-size(15px, 15px);
                        margin-bottom: 15px;
                        padding-bottom: 15px;
                    }

                    .title-desc {
                        margin-top: 10px;
                        font-weight: 400;
                        font-size: 13px;
                    }
                }

                .review-item {
                    @include font-size(12px, 12px);
                    color: $color--violet;
                    @include font(montserrat, medium);

                    &__lable {
                        display: inline-block;
                        font-weight: bold;
                        margin-bottom: 5px;

                        @include breakpoint(tablet) {
                            width: 50%;
                        }

                        @include breakpoint(mobile) {
                            width: 100%;
                        }
                    }

                    &__value {
                        display: inline-block;
                        margin-bottom: 17px;

                        @include breakpoint(mobile) {
                            display: block;
                        }
                    }
                }
            }

            .review-block--full-width {
                width: 100%;

                @include breakpoint(mobile) {
                    margin-bottom: 40px;
                }

                .review-item__lable {
                    width: 28.5%;

                    @include breakpoint(tablet) {
                        width: 50%;
                    }

                    @include breakpoint(mobile) {
                        width: 100%;
                    }
                }
            }
        }

        .application-review-trustees {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .review-item__lable {
                width: 45%;
            }
        }

        .missing-director-id-warning {
            border-left: 5px solid #ff000030;
            margin-bottom: 45px;
            padding: 5px 17px 17px 17px;

            & > h3 {
                margin-bottom: 15px;
                padding-top:10px;
                margin-top: 0px;
                color: #4538d1;
            }
        }

        .application-t-and-c {
            border-radius: 2px;
            margin-top: 30px;
            padding: 40px;
            background-color: $color--pale-blue-2;
            box-shadow: 3.019px 2.624px 0px 0px rgba(0, 0, 0, 0.04);

            @include breakpoint(not-desktop) {
                margin-top: 20px;
                padding: 30px 20px 40px 20px;
            }

            form {
                width: 100%;
            }

            .application-t-and-c__tile {
                @include font-size(15px, 15px);
                font-weight: bold;
                color: $color--blue-2;
            }

            .application-t-and-c__desc {
                @include font-size(12px, 15px);
                font-weight: 500;
                color: $color--violet;
                line-height: 20px;

                p {
                    margin-top: 0;
                }

                a {
                    color: $color--violet;
                }
            }

            .fieldWrapper {
                margin-top: 28px;

                label {
                    @include font-size(12px, 12px);
                    font-weight: bold;
                    color: $color--violet;
                }
            }

            .application-t-and-c__btn {
                margin-top: 28px;
                display: flex;
                flex-direction: row-reverse;

                .btn {
                    margin-bottom: 0;
                    position: relative;
                }

                .loading-spinner {
                    width: 25px;
                    position: absolute;
                    right: 11px;
                    top: 50%;
                    transform: translateY(-50%);

                    @include breakpoint(mobile){
                        right: 35px;
                    }

                    &--hide {
                        display: none;
                    }
                }

                input {
                    margin: 0;
                    padding: 0;
                    background: none;
                }
            }
        }

        .application-review-footer {
            @include breakpoint(not-mobile) {
                text-align: right;
            }

            @include breakpoint(tablet) {
                margin-bottom: 10px;
            }
        }

        .revise-link {
            @include font-size(16px, 16px);
            font-weight: bold;

            @include breakpoint(mobile) {
                @include font-size(14px, 14px);
                margin-bottom: 40px;
            }
        }
    }

    .onboarding-journey-progress {
        left: $dark-bcg-start-point;
        margin-left: 50px;
        transform: translateZ(0);

        &.fixed-position {
            position: fixed;
            top: calc(10vh + 169.67px);
        }

        &.absolute-position {
            position: absolute;
            top: unset;
            bottom: 60px;
        }

        @include breakpoint(tablet) {
            left: $dark-bcg-start-point-tb;
            top: 190px;
            margin-left: 30px;
            max-width: 180px;

            &-item-desc__text {
                display: none;
            }
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }
}

