.page-faqs {
    .page-content {
        position: relative;
        background-color: $color--alice-blue;

        @include breakpoint(desktop) {
            margin-bottom: 123px;
        }

        @include breakpoint(tablet) {
            margin-bottom: 85px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 100px;
        }

        &.sticky{
            padding-top: 100px;
        }
    }

    .page-section-content {
        position: relative;
        min-height: 376px;
        background-color: $color-white;
        padding-top: 35px;
        @include breakpoint(tablet) {
            padding-top: 23px;
        }

        @include breakpoint(mobile) {
            padding-top: 10px;
        }
    }
}
