/*
==========================================================================
   #icons
========================================================================== */
@font-face {
    font-family: "Icons";
    src: url('../fonts/icon-fonts/Icons.eot');
    src: url('../fonts/icon-fonts/Icons.eot?#iefix') format('eot'),
    url('../fonts/icon-fonts/Icons.woff2') format('woff2'),
    url('../fonts/icon-fonts/Icons.woff') format('woff'),
    url('../fonts/icon-fonts/Icons.ttf') format('truetype'),
    url('../fonts/icon-fonts/Icons.svg#Icons') format('svg');
}

@mixin icon-styles {
    font-family: "Icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
}

%icon {
    @include icon-styles;
}

@function icon-char($filename) {
    $char: "";

    @if $filename == arrow-left {
    $char: "\E001";
}
    @if $filename == arrow-right {
    $char: "\E002";
}
    @if $filename == bulb {
    $char: "\E003";
}
    @if $filename == cross {
    $char: "\E004";
}
    @if $filename == eye {
    $char: "\E005";
}
    @if $filename == facebook {
    $char: "\E006";
}
    @if $filename == linkedin {
    $char: "\E007";
}
    @if $filename == mouse {
    $char: "\E008";
}
    @if $filename == people {
    $char: "\E009";
}
    @if $filename == plus {
    $char: "\E00A";
}
    @if $filename == question-mark {
    $char: "\E00B";
}
    @if $filename == search {
    $char: "\E00C";
}
    @if $filename == tick {
    $char: "\E00D";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
    @if $extend {
        @extend %icon;
    } @else {
        @include icon-styles;
    }
    content: icon-char($filename);
}
}

.icon-arrow-left, %icon-arrow-left {
    @include icon(arrow-left);
}
.icon-arrow-right, %icon-arrow-right {
    @include icon(arrow-right);
}
.icon-bulb, %icon-bulb {
    @include icon(bulb);
}
.icon-cross, %icon-cross {
    @include icon(cross);
}
.icon-eye, %icon-eye {
    @include icon(eye);
}
.icon-facebook, %icon-facebook {
    @include icon(facebook);
}
.icon-linkedin, %icon-linkedin {
    @include icon(linkedin);
}
.icon-mouse, %icon-mouse {
    @include icon(mouse);
}
.icon-people, %icon-people {
    @include icon(people);
}
.icon-plus, %icon-plus {
    @include icon(plus);
}
.icon-question-mark, %icon-question-mark {
    @include icon(question-mark);
}
.icon-search, %icon-search {
    @include icon(search);
}
.icon-tick, %icon-tick {
    @include icon(tick);
}
