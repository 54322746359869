.page-unavailable {
    .page-content {
        padding-top: 0;
    }

    .fluid-banner {

        @include breakpoint(not-mobile) {
            padding-bottom: 0;
        }
        @include breakpoint(tablet) {
            padding-top: 160px;
        }

        &__wrapper {
            @include breakpoint(desktop) {
                min-height: 715px;
            }

            @include breakpoint(not-mobile) {
                display: block;
            }

            @include breakpoint(tablet) {
                min-height: 610px;
            }

            @include breakpoint(mobile) {
                display: flex;
                flex-direction: column;
                background-color: transparent;
            }
        }

        &__heading {
            @include font-size(50px, 59px);
            margin-bottom: 22px;

            @include breakpoint(desktop) {
                width: 100%;
            }

            @include breakpoint(tablet) {
                @include font-size(40px, 48px);
            }

            @include breakpoint(mobile) {
                background-color: $color--pale-blue;
                @include font-size(28px, 34px);
                margin-bottom: 0;
                padding-bottom: 20px;
                padding-left: $wrapper-padding-mb;
                padding-right: $wrapper-padding-mb;
            }
        }

        &__detail {
            @include breakpoint(desktop) {
                width: 580px;
            }

            @include breakpoint(tablet) {
                width: 380px;
                padding-bottom: 95px;
            }

            @include breakpoint(mobile) {
                order: 3;
                background-color: $color--blue-2;
                padding-top: 40px;
            }
        }

        &__desc {
            @include font-size(18px, 26px);

            @include breakpoint(tablet) {
                margin-bottom: 26px;
            }

            @include breakpoint(mobile) {
                color: $color--white;
                @include font-size(15px, 21px);
            }
        }

        &__img {
            text-align: center;

            @include breakpoint(desktop) {
                width: 1000px;
            }

            @include breakpoint(not-mobile) {
                position: absolute;
                right: 0;
                bottom: 0;
            }

            @include breakpoint(tablet) {
                width: 615px;
            }

            img {
                @include breakpoint(mobile) {
                    z-index: 1;
                    position: relative;
                }
            }
        }

        .btn {
            @include breakpoint(not-mobile) {
                min-width: 250px;
                margin-top: 100px;
            }

            @include breakpoint(mobile) {
                margin-bottom: 0;

                &.btn--mb-aqua {
                    background-color: $color-btn-aqua;
                    color: $color--free-speech-blue;

                    &:hover {
                        background-color: $color-btn-aqua--hover;
                        color: $color--white;
                    }
                }
            }
        }
    }
}
