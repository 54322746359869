$module: 'fluid-banner';

.#{$module} {
    position: relative;
    padding-bottom: 120px;
    background-color: $color--pale-blue;
    overflow: hidden;

    @include breakpoint(desktop) {
        padding-top: calc(10vh + 169.67px);
    }

    @include breakpoint(not-mobile) {
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: $dark-bcg-start-point;
            right: 0;
            background-color: $color--blue-2;
        }
    }

    @include breakpoint(tablet) {
        padding-top: 185px;
        padding-bottom: 70px;
        &:after {
            left: $dark-bcg-start-point-tb;
        }
    }

    @include breakpoint(mobile) {
        padding-top: 105px;
        padding-bottom: 0;
    }

    &__wrapper {
        position: relative;

        @include breakpoint(not-mobile) {
            display: flex;
            margin-right: 0;
            z-index: 1;
        }

        @include breakpoint(desktop) {
            margin-left: calc((100% - #{$wrapper-width}) / 2);
        }

        @include breakpoint(tablet) {
            margin-left: $wrapper-padding-tb;
        }

        @include breakpoint(mobile) {
            background-color: $color--blue-2;
        }
    }

    &__detail {

        position: relative;

        @include breakpoint(desktop) {
            flex: 0 0 600px;
        }

        @include breakpoint(tablet) {
            flex: 0 0 400px;
        }

        @include breakpoint(mobile) {
            padding-bottom: 45px;
            background-color: $color--pale-blue;
            padding-left: $wrapper-padding-mb;
            padding-right: $wrapper-padding-mb;
            position: static;
        }

        .section-slider & {
            @include breakpoint(desktop) {
                padding-left: 100px;
                padding-right: 35px;
            }

            @include breakpoint(tablet) {
                padding-left: 100px;
                padding-right: 25px;
            }
        }
    }

    &__heading {
        @include font-size(62px, 73px);
        @include font(lora, bold);
        color: $color--blue-2;
        margin-bottom: 35px;

        @include breakpoint(tablet) {
            @include font-size(40px, 50px);
            margin-top: 0;
            margin-bottom: 20px;
        }

        @include breakpoint(mobile) {
            @include font-size(31px, 34px);
            margin-top: 0;
            margin-bottom: 25px;
        }

        span {
            display: block;
        }
    }

    &__desc {
        @include font-size(20px, 29px);
        color: $color--blue-2;
        margin-bottom: 53px;

        @include breakpoint(tablet) {
            @include font-size(15px, 22px);
            margin-bottom: 23px;
        }

        @include breakpoint(mobile) {
            @include font-size(14px, 19px);
            margin-bottom: 33px;
        }

        &--small {
            @include font-size(16px, 24px);
        }
    }

    .btn {
        position: relative;
        z-index: 1;
    }
}
