.page-engagement {

    &--form {
        width: 100% !important;
        margin-top: 25px;
    }

    .fluid-banner {
        &__detail {
            @include breakpoint(desktop) {
                flex: 0 0 800px;
            }
        
            @include breakpoint(tablet) {
                flex: 0 0 600px;
                width: calc((100vw - 350px));
            }
        }
    }

    .page-content {
        @include breakpoint(mobile) {
            flex: 1 0 0;
            background-color: $color--pale-blue;
        }
    }

    .engagement-letter-wrapper {
        margin-bottom: 50px;
        font-weight: 200;
        font-size: 13px;
        color: #4538d1;
        background-color: #fff;
        text-align: justify;
        border-radius: 2px;
        max-height: calc(100vh - 300px);
        padding: 10px 25px 10px 25px;
        line-height: 1.5;
        overflow-y: scroll;
    
        h3 {
            margin-top: 15px;
            font-size: 20px;
        }
    
        ul, ol {
            margin-left: 5px;
            text-align: left;
        }
    }

    .loading-spinner {
        width: 25px;
        position: absolute;
        right: 11px;
        top: 50%;
        transform: translateY(-50%);
    
        @include breakpoint(mobile){
            right: 35px;
        }
    
        &--hide {
            display: none;
        }
    }

    .accountant-detail-wrapper {
        margin-top: 50px;
        margin-bottom: 35px;
    }

    .disabled {
        color: #ddd;
    }

    .CheckboxInput label {
        @include breakpoint(mobile) {
            color: #fff;
        }
    }

    .CheckboxInput label::before {
        @include breakpoint(mobile) {
            color: #4538d1;
        }
    }

    .btn--purple {
        @include breakpoint(mobile) {
            background-color: $color-btn-aqua;
            color: $color--free-speech-blue;
        }

        &:visited {
            @include breakpoint(mobile) {
                color: $color--free-speech-blue;
            }
        }

        &:hover, &:focus {
            @include breakpoint(mobile) {
                background-color: $color-btn-aqua--hover;
                color: $color--white;
            }
        }
    }
}