/*
==========================================================================
   #Links
========================================================================== */
a {
	text-decoration: underline;
    text-underline-position: under;
	color: $color-link-visited;
	-webkit-transition: color 0.2s;
	transition: color 0.2s;

	&:visited {
		color: $color-link-visited;
	}

	&:focus,
	&:hover {
		color: $color-link-focus;
		text-decoration: underline;
	}
}
