/*
==========================================================================
   #Button menu
========================================================================== */

.burger-icon {
    display: none;
    z-index: z-index(btn-menu);
    margin: 0;
    padding: 0;
    position: relative;
    width: 27px;
    height: 18px;
    transition: .5s ease-in-out;
    cursor: pointer;

    @include breakpoint(not-desktop) {
        display: block;
    }

    @include breakpoint(tablet) {
        span {
            background-color: $color--white;
        }
    }

    @include breakpoint(mobile) {
        span {
            background-color: $color-button-menu-bg;
        }
    }

    span {
        display: block;
        position: absolute;
        left: 0;
        height: 3px;
        width: 100%;
        opacity: 1;
        transform: rotate(0);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2), &:nth-child(3) {
            top: 8px;
        }

        &:nth-child(2), &:nth-child(3) {
            top: 8px;
        }

        &:nth-child(4) {
            top: 16px;
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                top: 8px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 8px;
                width: 0%;
                left: 50%;
            }
        }

        @include breakpoint(tablet) {
            span {
                background-color: $color-button-menu-bg;
            }
        }
    }

    &:after {
        content: '';
        position: absolute;
        top: -20px;
        left: -20px;
        padding: 20px;
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: content-box;
    }

}
