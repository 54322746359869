.block-image_text_block {
    @extend %clearfix;
    margin-bottom: 60px;

    @include breakpoint (mobile) {
        margin-bottom: 30px;
    }

    .image {
        margin-bottom: 10px;

        @include breakpoint (mobile-portrait) {
            width: 100%;
            text-align: center;
        }
    }

    .image--left {
        @include breakpoint (not-mobile) {
            float: left;
            margin-right: 40px;
        }
    }

    .image--right {
        @include breakpoint (not-mobile) {
            float: right;
            margin-left: 40px;
        }
    }
}
