.page-section {
    padding-top: 110px;
    padding-bottom: 110px;

    @include breakpoint(tablet) {
        padding-top: 70px;
        padding-bottom: 60px;
    }

    @include breakpoint(mobile) {
        padding-top: 55px;
        padding-bottom: 60px;
    }

    &__heading {
        @include font(lora, bold);
        font-size: 36px;
        margin-top: 0;

        @include breakpoint(tablet) {
            font-size: 30px;
        }

        @include breakpoint(mobile) {
        font-size: 28px;
        }
    }
}
