.standard-page {
    .page-title {
        background-color: $color--alice-blue;
        padding-top: 52px;
        padding-bottom: 52px;
        margin-bottom: 60px;

        @include breakpoint(mobile) {
            padding-top: 34px;
            padding-bottom: 34px;
            margin-bottom: 10px;
        }

        h1 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .with-breadcrumbs {
        padding-top: 27.5px;
    }

    .breadcrumb {
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: bold;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .page-content {
        padding-bottom: 80px;

        img {
            height: auto;
        }
    }
}
