.tooltip-icon {
    @extend %icon-question-mark;
    line-height: 1;
    font-size: 20px;
    color: $color-tooltip-icon;
    cursor: pointer;
}

.tooltip {
    z-index: 1;
    background-color: $color--light-green;
    @include font-size(13px, 18px);
    margin: 20px;
    max-width: 366px;
    padding: 17px 35px 17px 53px;
    color: $color-white;
    // Added box-shadow to make overlay more obvious. It is not in design but recommend by tester.
    box-shadow: -5px 5px 0 rgba(0, 0, 0, 0.25);

    @include breakpoint(not-desktop) {
        margin: 15px;
    }

    // question mark icon
    @extend %icon-question-mark;
    &:before {
        position: absolute;
        top: 16px;
        left: 18px;
        font-size: 20px;
        line-height: 1;
    }

    &-arrow {
        position: absolute;

        [x-placement^="right"] & {
            right: 100%;
            border-style: solid;
            border-width: 7.5px 8px 7.5px 0;
            border-color: transparent $color--light-green transparent transparent;
        }

        [x-placement^="left"] & {
            left: 100%;
            border-style: solid;
            border-width: 7.5px 0 7.5px 8px;
            border-color: transparent transparent transparent $color--light-green;
        }

        [x-placement^="top"] & {
            top: 100%;
            border-style: solid;
            border-width: 8px 7.5px 0 7.5px;
            border-color: $color--light-green transparent transparent transparent;
        }

        [x-placement^="bottom"] & {
            bottom: 100%;
            border-style: solid;
            border-width: 0 7.5px 8px 7.5px;
            border-color: transparent transparent $color--light-green transparent;
        }
    }

    &-close {
        @extend %icon-cross;
        font-size: 11px;
        line-height: 1;
        position: absolute;
        top: 12px - 3px;
        right: 10px - 3px;
        cursor: pointer;
        padding: 3px;
    }
}
