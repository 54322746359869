$search-input-width: 670px;
$clear-btn-size: 52px;
$clear-btn-size-mb: 42px;
$submit-btn-width: 270px;
$search-input-margin-right-tb: 20px;

.page-section__search {
    background-color: $color--alice-blue;
    padding-top: 50px;
    padding-bottom: 50px;

    @include breakpoint(tablet) {
        padding-top: 40px;
    }

    @include breakpoint(mobile) {
        padding-top: 34px;
        padding-bottom: 40px;
    }

    .wrapper {

        @include breakpoint(not-mobile) {
            display: flex;
            justify-content: space-between;
        }

        @include breakpoint(tablet) {
            flex-wrap: wrap;
        }
    }

    h1 {
        margin-top: 0;
        margin-bottom: 0;

        @include breakpoint(tablet) {
            width: 100%;
            margin-bottom: 35px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 25px;
        }
    }
}

.form--search {
    position: relative;

    display: flex;

    @include breakpoint(tablet) {
        width: 100%;
    }

    ::-webkit-input-placeholder {
        color: $color-textinput-text;
        opacity: 1;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: $color-textinput-text;
        opacity: 1;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: $color-textinput-text;
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: $color-textinput-text;
        opacity: 1;
    }

    input[type=search] {
        padding-top: 12px;
        padding-bottom: 12px;
        margin-right: 30px;
        padding-right: $clear-btn-size;
        color: $color--violet;

        @include breakpoint(desktop) {
            width: $search-input-width;
        }

        @include breakpoint(tablet) {
            flex: 1 0 0;
            margin-right: $search-input-margin-right-tb;
        }

        @include breakpoint(mobile) {
            padding-top: 8px;
            padding-bottom: 8px;
            margin-bottom: 10px;
            padding-right: $clear-btn-size-mb;
            margin-right: 0;
        }
    }

    .btn {
        margin-bottom: 0;

        @include breakpoint(desktop) {
            padding-right: 88px;
            padding-left: 88px;
        }

        @include breakpoint(tablet) {
            width: $submit-btn-width;
        }

        @include breakpoint(mobile) {
            height: $clear-btn-size-mb;
            width: $clear-btn-size-mb;
        }
    }

    .btn[ type=submit ] {
        @include breakpoint(mobile) {
            min-width: unset;
            padding-right: 10px;
            padding-left: 10px;
            font-size: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

    }

    .icon-search {
        margin-right: 18px;
        line-height: 1;

        @include breakpoint(mobile) {
            margin-right: 0;
            font-size: 14px;
        }
    }

    .btn--clear {
        display: none;
        position: absolute;
        left: calc(#{$search-input-width} - #{$clear-btn-size});
        background: transparent;
        margin-top: 0;
        top: 0;
        height: $clear-btn-size;
        width: $clear-btn-size;
        color: $color--dark-violet;

        &:focus {
            &:before {
                border: 1.5px solid $color--pale-blue;
            }
        }

        &:focus:active {
            &:before {
                border: none;
            }
        }

        @include breakpoint(tablet) {
            left: auto;
            right: calc(#{$search-input-margin-right-tb} + #{$submit-btn-width});
        }

        @include breakpoint(mobile) {
            right: $clear-btn-size-mb;
            left: auto;
            height: $clear-btn-size-mb;
            width: $clear-btn-size-mb;
        }

        &:hover {
            color: $color--blue;
        }

        &.show {
            display: block;
        }
    }
}

#search-results {
    display: none;
}

.no-results {
    @include breakpoint(mobile) {
        padding-bottom: 30px;
    }
}

.autocomplete-suggestions {
    background-color: $color--blue-3;
    color: $color--violet;
    padding: 18px 25px 25px;
    overflow-y: auto;
    z-index: z-index(autocomplete-suggestions) !important;

    @include breakpoint(desktop) {
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f0f5fc;
        }

        &::-webkit-scrollbar {
            width: 10px;
            background-color: #a6b4c7;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #a6b4c7;
        }
    }

    @include breakpoint(mobile) {
        padding: 18px 15px 25px;
    }

    .see-all {
        display: inline-block;
        margin-top: 20px;

        @include breakpoint(mobile) {
            margin-right: 5px;
        }
    }
}

.autocomplete-suggestion {
    margin-bottom: 7px;
    padding: 5px;
    cursor: default;

    @include breakpoint(mobile) {
        margin-bottom: 12px;
    }

    &.autocomplete-selected {
        background-color: $color--white;
    }
}
