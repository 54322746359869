.slider {
    .fluid-banner__img:not(:first-child) {
        visibility: hidden;
        display: none;
    }

    &.slick-initialized {
        .fluid-banner__img:not(:first-child) {
            visibility: visible;
            display: block;
        }
    }
}

.slick-list {
    position: relative;
    z-index: 1;
}

.slick-dots {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    z-index: 1;


    @include breakpoint(desktop) {
        top: 95px;
    }

    @include breakpoint(not-mobile) {
        position: absolute;
        left: 0;
    }

    @include breakpoint(tablet) {
        top: 0;
    }

    @include breakpoint(mobile) {
        display: flex;
        justify-content: center;
    }

    li {
        margin-bottom: 7px;
        padding-left: 0;
        font-size: 0;
        line-height: 0;

        @include breakpoint(mobile) {
            margin-bottom: 0;
            padding-left: 13px;
            padding-right: 13px;
        }
    }

    button {
        padding: 0;
        @include circle(10px, $color--white);

    }

    .slick-active {
        button {
            background: $color--blue-2;

            @include breakpoint(mobile) {
                background-color: $color--aqua;
            }
        }
    }
}



