.page-home {
    .page-content {
        padding-top: 0;
        overflow: hidden;
    }
}

.home__fluid-banner {

    @include breakpoint(not-mobile) {
        -webkit-clip-path: ellipse(156% 80% at 50% 20%);
        clip-path: ellipse(156% 80% at 50% 20%);
    }

    @include breakpoint(desktop) {
        min-height: 850px;
        height: 90vh;
        max-height: 1080px;
    }

    .btn {
        min-width: 210px;

        &:first-of-type {
            margin-right: 10px;
        }
    }

    .fluid-banner__wrapper {
        @include breakpoint(mobile) {
            &:before {
                width: 470px;
                height: 470px;
                left: 110px;
                right: auto;
                top: 0px;
                content: '';
                display: block;
                border-radius: 50%;
                background-color: rgba(225, 234, 248, .1);
                box-shadow: 0 3px 117px 0 rgba(0, 0, 0, .12);
                position: absolute;
                z-index: 1;
            }
        }
    }

    .bubble {
        @include breakpoint(not-mobile) {
            position: relative;
            z-index: 1;
        }
        @include breakpoint(mobile) {
            display: none;
        }
        &:before {
            content: '';
            display: block;
            @include circle(655px, rgba(225, 234, 248, .10));
            box-shadow: 0px 3px 117px 0 rgba(0, 0, 0, .08);
            position: absolute;
            right: calc((100% - #{$wrapper-width}) / 2 + 72px);

            @include breakpoint(tablet) {
                right: $wrapper-padding-tb;
                width: 375px;
                height: 375px;
            }
        }
    }

    .fluid-banner__videos {
        background-color: transparent;
        max-width: none;
        display: inline-block;
        max-width: 830px;
        
        margin-right: 30px;
        margin-top: 40px;

        @include breakpoint(tablet) {
            max-width: 145%;
        }

        @include breakpoint(mobile) {
            max-width: 120%;
            margin-right: 0px;
            margin-top: 0px;
        }

        video {
            width: 100%;
            box-shadow: 0px 0px 15px $color--blue-2;
        }

        a {
            float:right;
            color: $color--white;
            margin-right: 2px;
            margin-bottom: 4px;
            text-decoration: none;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }
    }

    .fluid-banner__images {
        margin-top: 100px;
        overflow: hidden;

        @include breakpoint(desktop) {
            width: calc(100% - 600px);
        }

        @include breakpoint(tablet) {
            width: calc(100% - 400px);
            margin-top: 60px;
        }

        @include breakpoint(mobile) {
            background-color: $color--blue-2;
            padding-top: 45px;
            margin-top: 0;
            padding-left: $wrapper-padding-mb;
        }
    }

    .fluid-banner__img.slick-slide {
        text-align: left;

        img {
            max-width: none;
            display: inline-block;
            max-width: 830px;

            @include breakpoint(tablet) {
                max-width: 145%;
            }

            @include breakpoint(mobile) {
                max-width: 120%;
            }
        }
    }

    .scroll {
        position: fixed;
        bottom: 60px;
        left: calc((100% - #{$wrapper-width}) / 2);
        z-index: 1;
        text-decoration: none;
        font-weight: bold;
        color: $color--blue-2;

        &.absolute {
            position: absolute;
        }

        @include breakpoint(tablet) {
            bottom: 50px;
            left: $wrapper-padding-tb;
        }

        @include breakpoint(mobile) {
            display: none;
        }

        .vertical-text {
            @media (min-width: 1200px) and (max-height: 800px) {
                display: none;
            }

            @media (min-width: 768px) and (max-width: 1199px) and (max-height: 600px) {
                display: none;
            }
        }
    }

    .icon-mouse {
        display: block;
        opacity: 0.9;
        font-size: 37px;
        margin-top: 5px;
        animation-name: bounce;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }

    .slick-dots {
        @include breakpoint(mobile) {
            padding-top: 30px;
            padding-bottom: 30px;
            z-index: 1;
            position: relative;
        }
    }

}

.product-banner {
    text-align: center;

    .wrapper {
        @include breakpoint(mobile) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .page-section__heading {
        margin-bottom: 50px;

        @include breakpoint(tablet) {
            margin-bottom: 60px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 50px;
        }
    }

    .page-section__desc {
        text-align: center;
        max-width: 810px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 100px;

        @include breakpoint(not-mobile) {
            @include font-size(20px, 29px);
        }

        @include breakpoint(tablet) {
            max-width: 600px;
            margin-bottom: 75px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 55px;
        }
    }

    &__row {

        @include breakpoint(not-mobile) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    .slick-list {
        @include breakpoint(not-mobile) {
            width: 225px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        width: calc((100% - 60px * 3) / 3);
        margin-bottom: 80px;

        @include breakpoint(tablet-portrait) {
            width: calc((100% - 20px * 3) / 4);
            margin-bottom: 60px;
        }

        @include breakpoint(mobile) {
            width: 100vw;
            margin-bottom: 35px;

            &:not(:first-of-type) {
                visibility: hidden;

                .slick-initialized & {
                    visibility: visible;
                }
            }
        }

        &:first-of-type {
            img {
                width: 150px;

                @include breakpoint(desktop) {
                    margin-bottom: 0;
                }

                @include breakpoint(tablet) {
                    width: 118px;
                    margin-bottom: 17px;
                }
            }
        }

        &:nth-of-type(2) {
            img {
                width: 100px;

                @include breakpoint(desktop) {
                    margin-bottom: 4px;
                }

                @include breakpoint(tablet) {
                    width: 80px;
                    margin-bottom: 19px;
                }
            }
        }

        &:nth-of-type(3) {
            img {
                width: 104px;

                @include breakpoint(desktop) {
                    margin-bottom: 13px;
                }

                @include breakpoint(tablet) {
                    width: 87px;
                    margin-bottom: 28px;
                }
            }
        }

        img {
            margin-bottom: 35px;
            align-self: center;

            @include breakpoint(tablet) {
                margin-bottom: 30px;
            }

            @include breakpoint(mobile) {
                display: inline-block;
            }
        }
    }

    &__title {

        margin-top: 10px;
        margin-bottom: 15px;

        @include font-size(20px, 25px);
        font-weight: bold;
        color: $color--blue;

        @include breakpoint(tablet) {
            @include font-size(16px, 20px);
            margin-top: 5px;
        }

        @include breakpoint(mobile) {
            @include font-size(18px, 24px);
            margin-top: 0;
            width: 350px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__desc {
        margin-top: 0;
        margin-bottom: 0;

        @include font(montserrat, regular);

        @include breakpoint(mobile) {
            @include font-size(14px, 19px);
            margin-top: 0;
            width: 350px;
            margin-left: auto;
            margin-right: auto;
        }

        .slick-center & {
            @include breakpoint(mobile) {
                opacity: 1;
                transition: opacity $transition-time;
            }
        }
    }

    .btn {
        margin-bottom: 0;

        @include breakpoint(not-mobile) {
            min-width: 310px;
        }

        @include breakpoint(mobile) {
            margin-right: $wrapper-padding-mb;
            margin-left: $wrapper-padding-mb;
            width: calc(100% - #{$wrapper-padding-mb} * 2);
        }
    }

    .slick-arrow {
        position: absolute;
        top: 10px;
        padding: 0;
        width: 49px;
        height: 106px;
        border-radius: 100% / 50%;
        background-color: $color--hawkes-blue;
        font-size: 0;
        z-index: z-index(slider);

        &:before {
            font-size: 33px;
            color: $color-btn-purple;
        }

        &:hover {
            background-color: darken($color--hawkes-blue, 10%);
        }

        &.slick-disabled {
            background-color: #e1e1e1;
            cursor: default;

            &:before {
                color: $color--white;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .slick-prev {
        left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        @extend %icon, %icon-arrow-left;
        text-align: left;

        &:before {
            margin-left: 8px;
        }

    }

    .slick-next {
        right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @extend %icon, %icon-arrow-right;
        text-align: right;

        &:before {
            margin-right: 8px;
        }
    }
}

.control-banner {

    text-align: center;
    background-color: rgb(69, 56, 209);

    background-size: cover;
    background-position: center bottom;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        border-radius: 50%;
        background-image: linear-gradient(to top, rgb(157, 149, 230) 0%, rgb(255, 255, 255) 54%, rgb(255, 255, 255) 100%);
        box-shadow: 1px 3px 117px 0 rgba(0, 0, 0, .22);
        opacity: 0.11;
        position: absolute;
        right: calc((100% - #{$wrapper-width}) / 2);
        top: -130px;
        width: 278px;
        height: 278px;
        animation: float1 6s ease-in-out infinite;

        @include breakpoint(tablet-portrait) {
            right: -55px;
            top: -110px;
        }

        @include breakpoint(mobile) {
            width: 135px;
            height: 135px;
            right: -45px;
            top: -45px;
            opacity: 0.11;
        }
    }

    &:after {
        content: "";
        display: block;
        border-radius: 50%;
        background-image: linear-gradient(to top, $color--violet 0, $color-white 54%, $color-white 100%);
        opacity: .15;
        position: absolute;
        bottom: 20px;
        width: 480px;
        height: 480px;
        left: calc((100% - 1180px) / 2);
        animation: float1 6s ease-in-out infinite;
        @include breakpoint(tablet) {
            width: 340px;
            height: 340px;
            left: -50px;
        }
        @include breakpoint(mobile) {
            opacity: 0.11;
        }
    }

    .wrapper {

        &:before {
            content: "";
            display: block;
            border-radius: 50%;
            background-image: linear-gradient(to top, $color--violet 0, $color-white 20%, transparent 80%);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.9;
            animation: float4 6s linear infinite;
            @include breakpoint(tablet) {
                width: 740px;
                height: 740px;
            }
            @include breakpoint(mobile) {
                display: none;
            }
        }
    }

    .wrapper {
        @include breakpoint(mobile) {
            padding-left: 0;
            padding-right: 0;
        }
    }


    .page-section__heading {
        margin-bottom: 80px;
        color: $color--solitude-blue;

        @include breakpoint(tablet) {
            margin-bottom: 60px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 50px;
        }
    }

    &__title {

        margin-top: 10px;
        margin-bottom: 15px;

        @include font-size(20px, 25px);
        font-weight: bold;
        color: $color--pale-blue;

        @include breakpoint(tablet) {
            @include font-size(16px, 20px);
            margin-top: 5px;
        }

        @include breakpoint(mobile) {
            @include font-size(18px, 24px);
            margin-top: 0;
            width: 350px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__desc {
        margin-top: 0;
        margin-bottom: 80px;

        @include font(montserrat, regular);

        @include breakpoint(mobile) {
            @include font-size(14px, 19px);
        }
    }

    .btn {
        margin-bottom: 0;

        @include breakpoint(not-mobile) {
            min-width: 310px;
        }

        @include breakpoint(mobile) {
            margin-right: $wrapper-padding-mb;
            margin-left: $wrapper-padding-mb;
            width: calc(100% - #{$wrapper-padding-mb} * 2);
        }
    }
}

.how-banner {
    text-align: center;

    .wrapper {
        @include breakpoint(mobile) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .page-section__heading {
        margin-bottom: 80px;

        @include breakpoint(tablet) {
            margin-bottom: 60px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 50px;
        }
    }

    &__row {

        @include breakpoint(not-mobile) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    .slick-list {
        @include breakpoint(not-mobile) {
            width: 225px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        width: calc((100% - 60px * 3) / 4);
        margin-bottom: 80px;

        @include breakpoint(tablet-portrait) {
            width: calc((100% - 20px * 3) / 4);
            margin-bottom: 60px;
        }

        @include breakpoint(mobile) {
            width: 100vw;
            margin-bottom: 35px;

            &:not(:first-of-type) {
                visibility: hidden;

                .slick-initialized & {
                    visibility: visible;
                }
            }
        }

        &:first-of-type {
            img {
                width: 150px;

                @include breakpoint(desktop) {
                    margin-bottom: 0;
                }

                @include breakpoint(tablet) {
                    width: 118px;
                    margin-bottom: 17px;
                }
            }
        }

        &:nth-of-type(2) {
            img {
                width: 100px;

                @include breakpoint(desktop) {
                    margin-bottom: 4px;
                }

                @include breakpoint(tablet) {
                    width: 80px;
                    margin-bottom: 19px;
                }
            }
        }

        &:nth-of-type(3) {
            img {
                width: 110px;

                @include breakpoint(desktop) {
                    margin-bottom: 13px;
                }

                @include breakpoint(tablet) {
                    width: 87px;
                    margin-bottom: 28px;
                }
            }
        }

        &:nth-of-type(4) {
            img {
                width: 142px;

                @include breakpoint(desktop) {
                    margin-bottom: 12px;
                }

                @include breakpoint(tablet) {
                    width: 110px;
                    margin-bottom: 30px;
                }
            }
        }

        img {
            margin-bottom: 35px;
            align-self: center;

            @include breakpoint(tablet) {
                margin-bottom: 30px;
            }

            @include breakpoint(mobile) {
                display: inline-block;
            }
        }

        &:not(:last-of-type) {
            &:after {
                content: '';
                background-image: url(../img/dashed_arrow.svg);
                background-size: contain;
                background-repeat: no-repeat;
                width: 53px;
                height: 19px;
                position: absolute;
                left: 100%;
                top: 80px;

                @include breakpoint(tablet-portrait) {
                    left: auto;
                    right: -35px
                }

                @include breakpoint(mobile) {
                    display: none;
                }
            }
        }
    }

    &__desc {
        @include font-size(20px, 25px);
        color: $color--blue;
        margin-bottom: 0;

        @include breakpoint(tablet) {
            @include font-size(16px, 20px);
            margin-top: 5px;
        }

        @include breakpoint(mobile) {
            @include font-size(18px, 24px);
            margin-top: 0;
            width: 230px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .btn {
        margin-bottom: 0;

        @include breakpoint(not-mobile) {
            min-width: 310px;
        }

        @include breakpoint(mobile) {
            margin-right: $wrapper-padding-mb;
            margin-left: $wrapper-padding-mb;
            width: calc(100% - #{$wrapper-padding-mb} * 2);
        }
    }

    .slick-arrow {
        position: absolute;
        top: 10px;
        padding: 0;
        width: 49px;
        height: 106px;
        border-radius: 100% / 50%;
        background-color: $color--hawkes-blue;
        font-size: 0;
        z-index: z-index(slider);

        &:before {
            font-size: 33px;
            color: $color-btn-purple;
        }

        &:hover {
            background-color: darken($color--hawkes-blue, 10%);
        }

        &.slick-disabled {
            background-color: #e1e1e1;
            cursor: default;

            &:before {
                color: $color--white;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .slick-prev {
        left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        @extend %icon, %icon-arrow-left;
        text-align: left;

        &:before {
            margin-left: 8px;
        }

    }

    .slick-next {
        right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @extend %icon, %icon-arrow-right;
        text-align: right;

        &:before {
            margin-right: 8px;
        }
    }
}

.about-banner {
    background-size: cover;
    background-position: center bottom;
    text-align: center;
    position: relative;
    overflow: hidden;

    @include breakpoint(not-mobile) {
        background-image: linear-gradient(360deg, rgb(70,56,208) -40%, rgb(255, 255, 255) 30%, rgb(255, 255, 255) 100%);
    }

    @include breakpoint(mobile) {
        background-image: url(../img/bcg-circles-mb.jpg);
    }

    &:before {
        content: "";
        display: block;
        border-radius: 50%;
        background-image: linear-gradient(to top, $color--violet 0%, $color-white 54%, $color-white 100%);
        box-shadow: -1px 6px 56px 20px rgba(0, 0, 0, 0.23);
        opacity: .22;
        position: absolute;
        right: calc((100% - 1180px) / 2);
        top: -180px;
        width: 360px;
        height: 360px;
        animation: float2 6s linear infinite;

        @include breakpoint(tablet) {
            width: 300px;
            height: 300px;
            top: -150px;
            right: -150px;
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    &:after {
        content: "";
        display: block;
        border-radius: 50%;
        background-image: linear-gradient(to top, $color--violet 0, $color-white 54%, $color-white 100%);
        opacity: .15;
        position: absolute;
        bottom: 20px;
        width: 480px;
        height: 480px;
        left: calc((100% - 1180px) / 2);
        animation: float1 6s ease-in-out infinite;
        @include breakpoint(tablet) {
            width: 340px;
            height: 340px;
            left: -50px;
        }
        @include breakpoint(mobile) {
            display: none;
        }
    }

    .wrapper {
        max-width: 710px;

        &:before {
            content: "";
            display: block;
            border-radius: 50%;
            background-image: linear-gradient(to top, $color--violet 0, $color-white 20%, transparent 80%);
            position: absolute;
            width: 861px;
            height: 861px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.9;
            animation: float4 6s linear infinite;
            @include breakpoint(tablet) {
                width: 740px;
                height: 740px;
            }
            @include breakpoint(mobile) {
                display: none;
            }
        }
    }

    .page-section__heading {
        color: $color--violet;
        margin-bottom: 31px;

        @include breakpoint(tablet) {
            margin-bottom: 50px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 28px;
        }
    }

    .rich-text {
        * {
            color: $color--violet-3;
            margin-bottom: 25px;

            @include breakpoint(tablet) {
                margin-bottom: 20px;
            }

            @include breakpoint(mobile) {
                margin-bottom: 20px;
            }
        }

        > :first-child:not(p) {
            font-weight: 500;

            @include breakpoint(mobile) {
                @include font-size(16px, 22px);
            }
        }

        p {
            @include breakpoint(mobile) {
                @include font-size(14px, 19px);
                margin-bottom: 11px;
            }
        }
    }

    .btn {
        margin-top: 50px;
        margin-bottom: 0;

        @include breakpoint(tablet) {
            margin-top: 30px;
        }

        @include breakpoint(mobile) {
            margin-top: 25px;
        }
    }
}

.compare-banner {
    background-color: $color--free-speech-blue;
    text-align: center;
    color: $color--solitude-blue;

    @include breakpoint(mobile) {
        padding-top: 35px;
    }

    .wrapper {
        @include breakpoint(mobile) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .page-section__heading {
        color: $color--pale-blue;
        margin-bottom: 75px;

        @include breakpoint(mobile) {
            margin-bottom: 35px;
        }
    }

    &__row {
        display: flex;
    }

    &__col {
        padding-left: 29px;
        padding-right: 29px;
        display: flex;
        flex-direction: column;

        @include breakpoint(not-mobile) {
            flex: 1 0 0;
        }

        @include breakpoint(tablet) {
            padding-left: 7px;
            padding-right: 7px;
        }

        @include breakpoint(mobile) {
            padding-left: 0;
            padding-right: 0;

            &.slick-slide {
                display: flex;
            }
        }

        &--large {

            @include breakpoint(desktop) {
                flex: 0 0 410px;
            }

            @include breakpoint(tablet) {
                flex: 0 0 286px;
            }

            @include breakpoint(mobile) {
                width: 61vw;
            }
        }

        &--small {
            @include breakpoint(mobile) {
                width: 140px;
            }
        }
    }

    &__title {
        @include font-size(28px, 34px);
        @include font(montserrat, bold);
        margin-top: 0;
        margin-bottom: 5px;
        color: $color--aqua;
        align-self: center;

        @include breakpoint(tablet) {
            font-size: 26px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 15px;
            font-size: 24px;
            line-height: 24px;
            opacity: 0;
            transition: opacity $transition-time;
        }

        .compare-banner__col--large & {
            width: 163px;

            @include breakpoint(mobile) {
                width: 132px;
                margin-left: -50px;
                margin-right: -50px;
            }
        }

        .compare-banner__col--small & {
            @include breakpoint(mobile) {
                margin-left: -65px;
                margin-right: -65px;
                margin-bottom: 13px;
            }
        }

        .slick-center & {
            @include breakpoint(mobile) {
                opacity: 1;
                transition: opacity $transition-time;
            }
        }

        img {
            width: 100%;
            vertical-align: top;
        }
    }

    &__sub-tilte {
        @include font-size(16px, 24px);
        margin-top: 0;

        @include breakpoint(mobile) {
            margin-bottom: 30px;
            opacity: 0;
            transition: opacity $transition-time;
        }

        .compare-banner__col--large & {
            @include breakpoint(mobile) {
                margin-left: -50px;
                margin-right: -50px;
            }
        }

        .compare-banner__col--small & {
            @include breakpoint(mobile) {
                margin-left: -65px;
                margin-right: -65px;
            }
        }

        .slick-center & {
            @include breakpoint(mobile) {
                opacity: 1;
                transition: opacity $transition-time;
            }
        }
    }

    &__price-info {
        @include font(montserrat, bold);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;

        .compare-banner__col--small & {
            @include ring(256px, 18px, #4347d0);
            box-shadow: 1px 3px 59px 0px rgba(0, 0, 0, 0.2);
            margin-top: 25px;
            margin-bottom: 55px;

            @include breakpoint(tablet) {
                @include ring(195px, 13px, #4347d0);
                margin-top: 43px;
                margin-bottom: 57px;
            }

            @include breakpoint(mobile) {
                @include ring(140px, 10px, #4347d0);
                margin-top: 18px;
                margin-bottom: 52px;
            }
        }

        .compare-banner__col--large & {
            @include ring(311px, 24px, $color--aqua-2);
            margin-bottom: 25px;

            @include breakpoint(tablet) {
                @include ring(270px, 20px, $color--aqua-2);
            }

            @include breakpoint(mobile) {
                @include ring(179px, 12px, $color--aqua-2);
                margin-bottom: 32px;
            }
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;

            .compare-banner__col--large & {
                @include ring(220px, 24px, #39b6c8);
                top: -1px;
                left: -1px;

                @include breakpoint(tablet) {
                    @include ring(192px, 20px, #39b6c8);
                }

                @include breakpoint(mobile) {
                    @include ring(127px, 15px, #39b6c8);
                }
            }

            .compare-banner__col--small & {
                @include ring(184px, 18px, #4254d1);

                @include breakpoint(tablet) {
                    @include ring(137px, 16px, #4254d1);
                }

                @include breakpoint(mobile) {
                    @include ring(105px, 8px, #4254d1);
                }
            }
        }

        &:before {
            .compare-banner__col--small & {
                content: '';
                display: block;
                position: absolute;
                @include ring(174px, 6px, $color--aqua-2);
                top: 18px;
                left: 18px;

                @include breakpoint(tablet) {
                    @include ring(129px, 4px, $color--aqua-2);
                    top: 16px;
                    left: 16px;
                }

                @include breakpoint(mobile) {
                    @include ring(95px, 5px, $color--aqua-2);
                    top: 8px;
                    left: 8px;
                }

            }
        }

        span:not(.compare-banner__price) {
            @include font-size(16px, 22px);
            color: $color--aqua;

            @include breakpoint(tablet) {
                font-size: 15px;
            }

            @include breakpoint(mobile) {
                font-size: 9px;
                line-height: 12px;
            }
            .compare-banner__col--large & {
                font-size: 20px;

                @include breakpoint(tablet) {
                    font-size: 16px;
                }

                @include breakpoint(mobile) {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        span {
            display: block;

            &:not(:last-of-type) {
                .compare-banner__col--large & {
                    margin-bottom: 12px;

                    @include breakpoint(tablet) {
                        margin-bottom: 2px;
                    }

                    @include breakpoint(mobile) {
                        margin-bottom: 0;
                    }
                }

            }

            &:not(:last-of-type) {
                .compare-banner__col--small & {
                    margin-bottom: 7px;

                    @include breakpoint(tablet) {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    &__price {
        @include font-size(38px, 38px);

        @include breakpoint(tablet) {
            @include font-size(28px, 28px);
        }

        @include breakpoint(mobile) {
            @include font-size(20px, 20px);
        }

        .compare-banner__col--large & {
            @include font-size(52px, 52px);

            @include breakpoint(tablet) {
                @include font-size(45px, 45px);
            }

            @include breakpoint(mobile) {
                @include font-size(34px, 34px);
            }
        }
    }

    &__conditions {
        margin-top: 45px;
        margin-bottom: 0;
        @include font(montserrat, regular);
        @include font-size(15px, 15px);

        @include breakpoint(tablet) {
            @include font-size(14px, 14px);
        }

        @include breakpoint(mobile) {
            @include font-size(12px, 17px);
        }
    }

    &__desc {
        margin-top: 0;
        margin-bottom: 0;
        @include font(montserrat, regular);

        @include breakpoint(mobile) {
            @include font-size(14px, 19px);
            opacity: 0;
            transition: opacity $transition-time;
        }

        .compare-banner__col--large & {
            @include breakpoint(tablet) {
                padding-left: 20px;
                padding-right: 20px;
            }

            @include breakpoint(mobile) {
                margin-left: -50px;
                margin-right: -50px;
            }
        }

        .compare-banner__col--small & {
            @include breakpoint(mobile) {
                margin-left: -70px;
                margin-right: -70px;
            }
        }

        .slick-center & {
            @include breakpoint(mobile) {
                opacity: 1;
                transition: opacity $transition-time;
            }
        }
    }

    .btn {
        margin-top: 75px;
        margin-bottom: 0;

        @include breakpoint(not-mobile) {
            min-width: 310px;
        }

        @include breakpoint(mobile) {
            width: calc(100% - #{$wrapper-padding-mb} * 2);
            margin-top: 25px;
        }
    }
}

