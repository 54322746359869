/*
==========================================================================
   #Heading
========================================================================== */
%heading {
    line-height: 1.2em;
    margin-bottom: 11px;
    margin-top: 25px;
    border: 0 none;
    padding: 0;

    @include breakpoint(mobile) {
        margin-bottom: 10px;
    }
}

%h1,
h1 {
    @extend %heading;
    @include font(lora, bold);
    font-size: 36px;
    color: $color-h1;

    @include breakpoint(mobile) {
        font-size: 28px;
    }
}

%h2,
h2 {
    @extend %heading;
    @include font(montserrat, bold);
    font-size: 24px;
    color: $color-h2;

    @include breakpoint(mobile) {
        font-size: 18px;
    }
}

%h3,
h3 {
    @extend %heading;
    @include font(montserrat, bold);
    font-size: 22px;
    color: $color-h3;

    @include breakpoint(mobile) {
        font-size: 16px;
    }
}

%h4,
h4 {
    @extend %heading;
    @include font(montserrat, bold);
    font-size: 18px;
    color: $color-h4;

    @include breakpoint(mobile) {
        font-size: 16px;
    }
}

%h5,
h5 {
    @extend %heading;
    @include font(montserrat, bold);
    font-size: 18px;
    color: $color-h5;

    @include breakpoint(mobile) {
        font-size: 16px;
    }
}

%h6,
h6 {
    @extend %heading;
    @include font(montserrat, bold);
    font-size: 16px;
    color: $color-h6;


    @include breakpoint(mobile) {
        font-size: 14px;
    }
}

h1, h2, h3, h4, h5, h6 {
    a, a:focus, a:visited {
        color: inherit;
        text-decoration: none;
        font-family: inherit;
        font-size: inherit;
    }
    a:hover {
        color: inherit;
        text-decoration: underline;
    }

    &.center {
        text-align: center;
    }
}
