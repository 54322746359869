$module: 'accordion-item';

.block-accordion_block {
    margin-bottom: 45px;

    @include breakpoint(mobile) {
        margin-bottom: 52px;
    }
}

.accordion-groups__title {
    @include font(montserrat, bold);
    @include font-size(22px, 30px);
    margin-bottom: 25px;
    color: $color--blue;

    @include breakpoint(mobile) {
        @include font-size(20px, 28px);
        margin-bottom: 35px;
    }
}

.accordion-group__title {
    @include font(montserrat, bold);
    @include font-size(18px, 30px);
    color: $color-h2;
    margin-bottom: 16px;

    @include breakpoint(mobile) {
        @include font-size(18px, 19px);
    }
}

.#{$module} {
    &__title {
        color: $color--violet-3;
        @include font-size(16px, 24px);
        @include font(montserrat, medium);
        margin-bottom: 0;
        margin-top: 0;
        border-bottom: 3px solid $color--solitude-blue;
        padding: 22px 20px 22px 0;
        cursor: pointer;
        position: relative;
        @include icon(arrow-right, after);

        @include breakpoint(mobile) {
            padding: 15px 15px 15px 0;
            @include font-size(14px, 19px);
        }

        &:after {
            position: absolute;
            right: 0;
            top: 22px;
            color: $color--blue-2;
            font-size: 18px;
            transition: transform $transition-time, right $transition-time;

            @include breakpoint(mobile) {
                font-size: 14px;
                top: 16px;
            }
        }

        &:hover {
            &:after {
                .no-touch & {
                    right: -10px;
                }
            }
        }

        &.open {
            border-bottom-width: 0;
            font-weight: 700;

            &:after {
                transform: rotate(-90deg);
            }

            &:hover:after {
                right: 0;
            }
        }
    }

    &__content {
        display: none;
        border-bottom: 3px solid $color--solitude-blue;


        &__container {
            width: 100%;
            @include breakpoint(mobile) {
                flex-direction: column;
            }
        }

        &__btn{
            margin-bottom: 16px;
        }

        h1,h2,h3,h4,h5,h6 {
            @extend %h3;
            font-size: 15px;
            margin-bottom: 20px;
            margin-top: 0;

            @include breakpoint(mobile) {
                margin-bottom: 23px;
            }
        }

        img {
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-top: 35px;
            margin-bottom: 45px;

            @include breakpoint(mobile) {
                margin-top: 22px;
                margin-bottom: 30px;
            }
        }

        p {
            margin-top: 0;
        }
    }
}
