/*
==========================================================================
   #Fonts
========================================================================== */
$font-stack:
(group: lora, id: regular, font: ('Lora', 'Arial', 'serif'), weight: 400, style: normal),
(group: lora, id: bold, font: ('Lora', 'Arial', 'serif'), weight: 700, style: normal),
(group: montserrat, id: regular, font: ('Montserrat', 'sans-serif'), weight: 400, style: normal),
(group: montserrat, id: medium, font: ('Montserrat', 'sans-serif'), weight: 500, style: normal),
(group: montserrat, id: semi-bold, font: ('Montserrat', 'sans-serif'), weight: 600, style: normal),
(group: montserrat, id: bold, font: ('Montserrat', 'sans-serif'), weight: 700, style: normal),
(group: montserrat, id: black, font: ('Montserrat', 'sans-serif'), weight: 900, style: normal);


