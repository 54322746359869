/*
==========================================================================
   #Wrapper
========================================================================== */

.wrapper {
    display: block;
    max-width: $wrapper-width;
    padding: 0 10px;
    margin: auto;
    box-sizing: content-box;

    @include breakpoint(tablet) {
        padding-right: $wrapper-padding-tb;
        padding-left: $wrapper-padding-tb;
    }

    @include breakpoint(mobile) {
        padding-right: $wrapper-padding-mb;
        padding-left: $wrapper-padding-mb;
    }

}
