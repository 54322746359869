/*
==========================================================================
   #Page header
========================================================================== */
$module: 'site-header';
$header-padding-top: 58px;
$header-padding-bottom: 66px;
$header-padding-top-promo: 33px;
$header-padding-bottom-promo: 26px;
$header-padding-top-dk-sticky: 20px;
$header-padding-bottom-dk-sticky: 20px;
$header-padding-top-tb: 40px;
$header-padding-bottom-tb: 46px;
$header-padding-top-tb-sticky: 30px;
$header-padding-bottom-tb-sticky: 30px;
$header-padding-top-mb: 30px;
$header-padding-bottom-mb: 36px;
$header-padding-top-mb-sticky: 21px;
$header-padding-bottom-mb-sticky: 25px;

.#{$module} {
    background-color: $color--pale-blue;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: z-index(header);

    // Header shadow
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        box-shadow: 0 10px rgba(0, 0, 0, 0.25);
        opacity: 0;
        transition: all $transition-time;
    }

    &.sticky:before {
        opacity: 1;
    }

    .promo-strip {
        background-color: #ed5b5b;
        height: 60px;
        display: none;
        color: white;
        overflow: hidden;

        @include breakpoint(tablet) {
            height: 35px;   
            font-size: 12px;
        }

        @include breakpoint(mobile) {
            height: 35px;
            font-size: 12px;
        }
    }

    .promo-body {
        
        width: 100%;
        line-height: 60px;
        font-size: 18px;
        text-decoration: none;
        text-align: center;

        @include breakpoint(tablet) {
            line-height: 35px;
            font-size: 12px;
        }

        @include breakpoint(mobile) {
            line-height: 35px;
            font-size: 12px;
        }

        &__strong {
            font-weight: bold;
            margin-right: 5px;
        }

        &__normal {
            font-weight: 200;
        }

        a {
            color: white;
            text-decoration: underline;
        }

        a:hover {
        }

        &:before {
            content: '';
            display: block;
            border-radius: 50%;
            background-image: linear-gradient(to top, rgb(157, 149, 230) 0%, rgb(255, 255, 255) 54%, rgb(255, 255, 255) 100%);
            box-shadow: 1px 3px 117px 0 rgba(0, 0, 0, .22);
            opacity: 0.12;
            position: absolute;
            right: calc((100% - #{$wrapper-width}) / 2);
            top: -130px;
            width: 178px;
            height: 178px;
            animation: float1 12s ease-in-out infinite;

            @include breakpoint(tablet-portrait) {
                right: -55px;
                top: -110px;
            }

            @include breakpoint(mobile) {
                width: 135px;
                height: 135px;
                right: -45px;
                top: -45px;
                opacity: 0.11;
            }
        }
    }

    .promo-strip:hover {
        //background-color: #d55151;
        cursor: pointer;
    }
        
    &__wrapper {
        position: relative;
        transition: padding-top $transition-time, padding-bottom $transition-time;

        @include breakpoint(desktop) {
            
            
            padding-top: $header-padding-top;
            padding-bottom: $header-padding-bottom;

            .sticky & {
                padding-top: $header-padding-top-dk-sticky;
                padding-bottom: $header-padding-bottom-dk-sticky;
            }
        }

        // Purple background at right side
        &:after {
             @include breakpoint(not-mobile) {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: $dark-bcg-start-point;
                right: 0;
                background-color: $color--blue-2;
            }

            @include breakpoint(tablet) {
                left: $dark-bcg-start-point-tb;
            }
        }

        &--signin {
            &:after {
                background: transparent;
            }
        }
    }

    .wrapper {
        @include breakpoint(not-desktop) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        //align-items: flex-end;
        position: relative;
        z-index: 1;
        line-height: 1.7;

        &:after {
            content: '';
            position: fixed;
            z-index: z-index(overlay);
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 0;
            height: 0;
            opacity: 0;
            background: rgba(13, 10, 48, .82);
            transition: opacity .5s, width .1s .5s, height .1s .5s;

            @include breakpoint(mobile) {
                z-index: -1;
            }
        }

        .main-nav-open & {
            &:after {
                width: 100%;
                height: 100%;
                opacity: 1;
                transition: opacity .5s;
            }
        }
    }

    &__top {
        @include breakpoint(not-desktop) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            transition: padding-top $transition-time, padding-bottom $transition-time;
        }

        @include breakpoint(tablet) {
            padding: $header-padding-top-tb $wrapper-padding-tb $header-padding-bottom-tb;
        }

        @include breakpoint(mobile) {
            padding: $header-padding-top-mb $wrapper-padding-mb $header-padding-bottom-mb;
            background-color: $color--pale-blue;
            z-index: z-index(header);
        }

        .sticky & {
            @include breakpoint(tablet) {
                padding-top: $header-padding-top-tb-sticky;
                padding-bottom: $header-padding-bottom-tb-sticky;
            }

            @include breakpoint(mobile) {
                padding-top: $header-padding-top-mb-sticky;
                padding-bottom: $header-padding-bottom-mb-sticky;
            }
        }
    }

    &__logo {
        img {
            transform-style: preserve-3d;
            backface-visibility: hidden;
            transition: transform $transition-time;
        }
        @include breakpoint(not-desktop) {
            z-index: z-index(header-logo);
        }

        @include breakpoint(mobile) {
            width: 144px;
        }

        .sticky & {
            @include breakpoint(desktop) {
                img {
                    transform: scale(0.78) translateZ(0);
                    backface-visibility: hidden;
                }
            }
            @include breakpoint(tablet) {
                img {
                    transform: scale(0.83) translateZ(0);
                    backface-visibility: hidden;
                }
            }
        }
    }
}

