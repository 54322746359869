.page-register-step-2 {

    .page-content {
        position: relative;
        padding-top: 0;
    }

    .fluid-banner {
        padding-bottom: 60px;

        @include breakpoint(mobile) {
            padding-bottom: 0;
        }

        &__wrapper {
            width: 830px;
            display: block;
            z-index: 2;

            @include breakpoint(tablet) {
                margin-left: 30px;
                padding-top: 30px;
                width: calc(100vw - 330px);
            }

            @include breakpoint(mobile) {
                width: 100%;
                padding: 0 20px;
                background: $color--pale-blue;
            }
        }

        &__heading {
            text-align: left;
            margin-bottom: 0px;

            @include breakpoint(tablet) {
                @include font-size(35px, 35px);
            }

            @include breakpoint(mobile) {
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &__detail {
            @include breakpoint(mobile) {
                background: $color--pale-blue;
                padding-bottom: 20px;
                padding-right: 0;
                padding-left: 0;
            }
        }

        &__desc {
            margin-top: 0px;

            p {
                margin:5px 0 0 2px;
            }

            @include breakpoint(mobile) {
                color: $color--blue;
            }
        }

        form {
            width: 100%;

            .toggle-radio-input input[type=radio]+label {
                border: 0px;
            }

            .hide-frame {
                display: none;
            }
        }

        .form-colum {
            width: 355px;

            @include breakpoint(tablet) {
                width: 100%;
            }

            @include breakpoint(mobile) {
                width: 100%;
                padding-right: 0px;
            }

            &--left {
                width: 395px;
                padding-right: 40px;

                @include breakpoint(tablet) {
                    padding-right: 0;
                    width: 100%;
                }

                @include breakpoint(mobile) {
                    width: 100%;
                    padding-right: 0px;
                }
            }

            &--right {
                width: 435px;
                padding-left: 40px;
                padding-right: 40px;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 1px;
                    top: 0;
                    bottom: 20px;
                    left: 0;
                    background: $color--blue-2;

                    @include breakpoint(not-desktop) {
                        display: none;
                    }
                }

                @include breakpoint(not-desktop) {
                    border: none;
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0px;
                }
            }
        }

        .fieldWrapper {
            width: 100%;

            &.short-field {
                width: 48%;
            }

            &.Select {
                .tooltip-icon {
                    @include breakpoint(mobile) {
                        right: 50px;
                    }
                }
            }

            .loading-spinner {
                position: absolute;
                margin-left: 5px;
                padding: 0px;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
                background-image: url(/static/website/img/spinner.gif);
                background-repeat: no-repeat;
                background-position: right 11px center;
                background-size: 32px 32px;
                height: 50px;
                width: 50px;
                
                @include breakpoint(mobile) {
                    left: auto;
                    margin-left: 0;
                    right: 0px;
                    background-size: 25px 25px;
                }
            }
    
            .loading-spinner--hide {
                background-image:none;
            }

            .company-check-indicator {
                width: 25px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                font-size: 0.6em; 
                border-radius: 50%;
                position: absolute;
                top:  14px;
                left: 100%;
                margin-left: 7px;
                color: $color-white;
    
    
                @include breakpoint(mobile) {
                    top: 8px;
                    left: auto;
                    margin-left: 0;
                    right: 8px;
                }

                &__tick {
                    @extend %icon-tick;
                    background-color: $color--light-green;
                }
    
                &__cross {
                    @extend %icon-cross;
                    background-color: $color-red;
                }
            }
        }

        .toggle-radio-input {
            
            .tooltip-icon {
                position: absolute;
                margin-left: 5px;
                padding: 5px;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
               
            }

            @include breakpoint(mobile) {
                margin-right: 20px;
            }
        }

        .form-section__title {
            margin-bottom: 0px;
        }

        .form-section__title, .toggle-radio-label {
            display: block;
            @include font-size(16px, 24px);
            @include font(montserrat, bold);
            color: $color-form-section-title;
            margin-top: 55px;

            @include breakpoint(tablet) {
                margin-top: 45px;
                margin-bottom: 20px;
            }
        }

        .form__primary-trustee .form-section__title {
            margin-top: 35px;

            @include breakpoint(tablet) {
                margin-top: 25px;
            }

            @include breakpoint(mobile) {
                margin-top: 32px;
            }
        }

        .form__fund-info .form-section__title {

            @include breakpoint(mobile) {
                margin-top: 45px;
            }
        }

        .form__fund-type .form-section__title {
            max-width: 800px;
        }

        .fix-top-margin {
            @include breakpoint(desktop) {
                margin-top:40px;
            }
            @include breakpoint(tablet) {
                margin-top:30px;
            }
            @include breakpoint(mobile) {
                margin-top:30px;
            }
        }

        .form-section__sub-title {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;
            @include font(montserrat, medium);
            color: $color-form-section-sub-title;
            margin: 10px 0 20px 0;
            @include font-size(14px, 22px);

            @include breakpoint(not-desktop) {
                margin-top: 20px;
                margin-bottom: 10px;
            }

            .tooltip-icon {
                position: absolute;
                left: 365px;
                top: 50%;
                transform: translateY(-50%);
                @include breakpoint(tablet) {
                    left: 440px;
                    top: 0;
                    transform: translateY(-0);
                }
                @include breakpoint(mobile) {
                    left: calc(100% - 20px);
                    top: 0;
                    transform: translateY(-0);
                }
            }
        }

        .form__secondary-trustee, .form__member-trustee {
            .form-section__sub-title {
                margin-top: 35px;
                @include breakpoint(tablet) {
                    margin-top: 25px;
                }
                @include breakpoint(mobile) {
                    margin-top: 32px;
                }
            }
        }

        .force-block {
            display: block;
        }

        .trustee-form {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .form__address-info {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            .business-info {
                margin-top: 10px;
            }
        }

        .add-member-trustee-btn-wrapper {
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            width: 100%;
            padding-right: 40px;
            margin-bottom: 60px;

            @include breakpoint(tablet) {
                padding-right: 0px;
            }

            @include breakpoint(mobile) {
                padding-right: 35px;
            }

            .btn-tooltip-wrapper {
                display: flex;
                align-items: center;
            }

            .btn {
                margin: 0;

                @include breakpoint(tablet) {
                    width: 100%;
                }
            }

            .tooltip-icon {
                position: absolute;
                top: 50%;
                right: 40px;
                transform: translateX(100%) translateX(10px) translateY(-50%);

                @include breakpoint(tablet) {
                    right: 0px;
                }

                @include breakpoint(mobile) {
                    right: 30px;
                }
            }
        }

        .delete-member-trustee {
            cursor: pointer;
            font-weight: 400;
            margin-right: 40px;
            @include font-size(14px, 14px);
            color: $color-form-section-sub-title;
            background: none;
            text-decoration: underline;
            margin-top: 0;

            &:focus {
                outline: 1px solid $color-btn-focus-shadow-2;
            }

            @include breakpoint(not-desktop) {
                width: 100%;
                margin: 10px 0;
                text-align: left;
                padding-left: 0;
            }
        }

        input[type=checkbox] {
            &.disabled + label:hover {
                cursor: default;
            }

            &.disabled + label:before {
                background-color: $color-disabled-input;
            }
        }
    }

    .submit-btn-wrapper {
        display: flex;
        flex-direction: row-reverse;
        padding-right: 40px;
        margin-top: 60px;
        margin-bottom: 40px;

        @include breakpoint(tablet) {
            padding-right: 0px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 30px;
            padding-right: 0;
        }

        .btn {
            margin: 0;
        }
    }

    .form-footer {
        padding: 38px 40px 0px 0;
        color: $color--blue;
        text-align: center;
        border-top: 2px solid $color--blue-4;

        @include breakpoint(tablet) {
            padding: 38px 0px 0px 0;
        }

        @include breakpoint(mobile) {
            padding: 28px 0px 28px 0;
            @include font-size(12px, 16px);
        }
    }

    .onboarding-journey-progress {
        left: $dark-bcg-start-point;
        margin-left: 50px;
        transform: translateZ(0);

        &.fixed-position {
            position: fixed;
            top: calc(10vh + 169.67px);
        }

        &.absolute-position {
            position: absolute;
            top: unset;
            bottom: 60px;
        }

        @include breakpoint(tablet) {
            left: $dark-bcg-start-point-tb;
            top: 190px;
            margin-left: 30px;
            max-width: 180px;

            &-item-desc__text {
                display: none;
            }
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    #corporate-fund {
        .form-section__sub-title {
            display: block;
            margin: 104px 0;
        }
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    .jq-dte  {
        > input {
            display: block !important;
            visibility: hidden;
            height: 0;
            padding: 0;
            border: 0;

            &.invalid {
                border: 0;
            }
        }
    }

    .jq-dte-inner {
        display: flex;
        align-items: center;
        position: relative;
        background-color: $color--white;
        height: 52px;
        padding-right: 25px;
        padding-left: 25px;

        @include breakpoint (mobile) {
            height: 42px;
            padding-right: 20px;
            padding-left: 20px;
        }

        &.invalid {
            border: 2px solid $color-error-text;
        }

        input {
            background-color: transparent;
        }
    }

    .jq-dte-day {
        width: 30px!important;
        padding-left: 0;
        padding-right: 5px;
    }

    .jq-dte-month {
        width: 40px!important;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }

    .jq-dte-year {
        width: 50px!important;
        padding-left: 5px;
        padding-right: 0;
    }

    .jq-dte-errorbox {
        left: 0 !important;
        top: calc(100% + 3px) !important;
        color: $color--blue-2;
        font-size: 10px;

        @include breakpoint (mobile) {
            position: relative !important;
        }
    }

    .jq-dte{
        .separator {
            display: none;
            padding-top: 20px;

            @include breakpoint (mobile) {
                padding-top: 17px;
            }
        }

        &.focus, &.hasActive  {
            .separator {
                display: block;
            }

            & + label{
                font-size: 10px;
                line-height: 10px;
                top: 10px;
            }
        }
    }
}
