.page-auth {
    .page-content {
        background-color: $color--pale-blue;
    }

    .content-wrapper {
        height: 100%;
        padding-top: 130px;
        padding-bottom: 220px;
        @include breakpoint(tablet) {
            padding-top: 135px;
            padding-bottom: 200px;
        }
        @include breakpoint(mobile) {
            padding-top: 80px;
            padding-bottom: 40px;
        }
    }

    .form-error {
        padding: 13px 0;
        text-align: center;
        color: $color-error-text;
        min-height: 55px;
        text-align: center;
        @include breakpoint(mobile) {
            min-height: 45px;
        }
    }

    .form-wrapper {
        width: 428px;
        margin: 0 auto;
        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    .fieldWrapper--password {
        position: relative;

        .icon-eye {
            position: absolute;
            width: 20px;
            height: 10px;
            font-size: 10px;
            line-height: 10px;
            color: $color--blue;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }

    h1 {
        margin: 0;
        text-align: center;
        color: $color--blue-2;
    }

    .btn-wrapper {
        position: relative;
        height: 90px;
        @include breakpoint(mobile) {
            height: 60px;
        }
        .btn {
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            @include breakpoint(mobile) {
                margin-top: 0;
            }
        }
    }

    .links-wrapper {
        text-align: center;
        margin-top: 15px;
        a {
            margin: 0;
            font-size: 13px;
            @include breakpoint(mobile) {
                font-size: 12px;
            }
        }
        div:first-child {
            margin-bottom: 5px;
            @include breakpoint(mobile) {
                margin-bottom: 15px;
            }
        }
    }

    .intro-text {
        text-align: center;
        margin-bottom: 0;
    }
}
