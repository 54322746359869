#loading {
    display: none;
    margin: 50px 0;
}

.placeholder {
    width: calc(#{$wrapper-width});
    position: absolute;
    top: 0;

    @include breakpoint(tablet) {
        width: calc(100% - #{$wrapper-padding-tb} * 2);
    }

    @include breakpoint(mobile) {
        width: calc(100% - #{$wrapper-padding-mb} * 2);
    }

    &__item {
        position: relative;
        margin-bottom: 14px;

        @include breakpoint(mobile) {
            margin-bottom: 8px;
        }

        &.heading {
            margin-bottom: 34px;

            @include breakpoint(mobile) {
                margin-bottom: 24px;
            }
        }
    }
}


.animated-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: linear;
    background: linear-gradient(to right, #f0f5fc 8%, #dce7f7 18%, #f0f5fc 33%);
    background-size: 800px 70px;
    height: 18px;
    position: relative;
    margin-bottom: 5px;

    @include breakpoint(mobile) {
        height: 12px;
    }


    &--title {
        height: 26px;
        width: 200px;
        margin-bottom: 14px;

        @include breakpoint(mobile) {
            height: 22px;
            width: 150px;
            margin-bottom: 10px;
        }

        .heading & {
            width: 270px;

            @include breakpoint(mobile) {
                width: 250px;
            }
        }
    }

    &--arrow {
        height: 30px;
        width: 30px;
        position: absolute;
        right: 0;
        top: 0;

        @include breakpoint(mobile) {
            width: 20px;
            height: 20px;
            top: 4px;
        }
    }
}
