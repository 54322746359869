/*
==========================================================================
   #Page footer
========================================================================== */
$module: 'site-footer';
.#{$module} {
    padding: 54px 0 26px;
    background-color: $color--violet;
    color: $color-white;
    position: relative;
    margin-top: auto;

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0px;
        background: linear-gradient(to right, #4438cf 0%, #42e5f8 83%);
    }

    a {
        font-weight: normal;
        text-decoration: none;
        color: $color--white;
    }

    .wrapper {
        @include breakpoint(tablet) {
            padding-right: 27px;
        }

        font-weight: normal;
    }

    &__main {
        display: flex;
        align-items: flex-end;
        margin-bottom: 40px;

        @include breakpoint(tablet) {
            justify-content: space-between;
        }

        @include breakpoint(mobile) {
            justify-content: space-between;
        }
    }

    &__logo {
        width: 172px;

        @include breakpoint(tablet) {
            width: 136px;
        }

        @include breakpoint(mobile) {
            width: 130px;
        }

        img {
            width: 100%;
        }
    }

    &__menu {

        display: flex;
        justify-content: space-between;

        @include breakpoint(desktop) {
            max-width: 870px;
            min-width: 520px;
            margin-left: auto;
        }

        @include breakpoint(mobile) {
            display: none;
        }

        a {
            text-transform: uppercase;
            @include font(montserrat, semi-bold);
            @include font-size(16px, 12px);
            padding-right: 15px;
            padding-left: 15px;

            @include breakpoint(tablet) {
                @include font-size(14px, 14px);
                padding-right: 12px;
                padding-left: 12px;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__social {
        margin-left: 43px;
        line-height: 20px;

        @include breakpoint(tablet) {
            position: absolute;
            bottom: 46px;
            right: 34px;
        }

        a {
            padding-left: 6px;
            padding-right: 6px;
            font-size: 18px;

            @include breakpoint(mobile) {
                font-size: 23px;
            }

            &:hover {
                color: $color--light-blue;
            }
        }
    }

    &__bottom, &__mid {
        @include font-size(14px, 14px);

        @include breakpoint(tablet) {
            font-size: 12px;
            max-width: 515px;
        }

        @include breakpoint(mobile) {
            @include font-size(13px, 20px);
        }
    }

    &__mid {
        .rich-text{
             margin-bottom: 16px;
             line-height: 18px;;
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__links {
        display: inline-flex;
        margin-bottom: 16px;

        @include breakpoint(tablet) {
            display: inline;
        }

        @include breakpoint(mobile) {
            display: block;
        }

        a {
            &:not(:last-of-type):after, &:first-of-type:before {
                content: '';
                height: 14px;
                width: 1px;
                background: currentColor;
                margin-left: 12px;
                margin-right: 12px;
                display: inline-block;
                vertical-align: middle;

                @include breakpoint(tablet) {
                    margin-left: 9px;
                    margin-right: 9px;
                }
            }

            &:first-of-type:before {
                @include breakpoint(mobile) {
                    display: none;
                }
            }

            @include breakpoint(tablet) {
                line-height: 30px;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__development {

        @include breakpoint(tablet) {
            display: inline;
        }

        a {
            @include breakpoint(tablet) {
                line-height: 30px;
                margin-left: 20px;
            }
        }

        img {
            width: 86px;
            margin-top: -5px;
        }
    }
}

