/*
==========================================================================
   #Mixins
========================================================================== */

// #Breakpoints

@mixin breakpoint($point) {

    // Main break points
    @if $point == desktop {
        @media screen and (min-width: 1200px) {
            @content;
        }
    } @else if $point == tablet {
        @media screen and (min-width: 768px) and (max-width: 1199px) {
            @content;
        }
    } @else if $point == mobile {
        @media screen and (max-width: 767px) {
            @content;
        }
    }

        // Extra break points
    @else if $point == tablet-landscape {
        @media screen and (min-width: 1024px) and (max-width: 1219px) {
            @content;
        }
    } @else if $point == tablet-portrait {
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            @content;
        }
    } @else if $point == mobile-landscape {
        @media screen and (min-width: 481px) and (max-width: 767px) {
            @content;
        }
    } @else if $point == mobile-portrait {
        @media screen and (max-width: 480px) {
            @content;
        }
    } @else if $point == not-desktop {
        @media screen and (max-width: 1199px) {
            @content;
        }
    } @else if $point == not-mobile {
        @media screen and (min-width: 768px) {
            @content;
        }
    }
}

// #Font size
@mixin font-size($font-size, $line-height) {
    font-size: $font-size;
    line-height: $line-height / $font-size;
}

// #Font family
@mixin font($group, $id:regular) {
    @each $font in $font-stack {
        @if ($group == map-get($font, group) and $id == map-get($font, id)) {
            font-family: map-get($font, font);
            font-weight: map-get($font, weight);
            font-style: map-get($font, style);
        }
    }
}

/// -------------------------------------
/// $Z-INDEX
/// -------------------------------------

//exapmle:
//z-index: z-index(overlay);

$z-layers: (
        "header-logo": 6,
        "slider": 8,
        "overlay": 9,
        "nav": 10,
        "autocomplete-suggestions": 999,
        "header": 1000,
        "btn-menu": 1001,
) !default;

/// A function helper to avoid having to type `map-get($z-layers, ...)`
/// @param [string] $component: the layer to use
/// @return [number] | [null]
@function z-index($layer, $sub-layer: '') {

    @if map-has-key($z-layers, $layer) {
        @if $sub-layer != '' {
            @return map-get(map-get($z-layers, $layer), $sub-layer);
        } @else {
            @return map-get($z-layers, $layer);
        }
    } @else {
        @warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
    }
}

@mixin circle($radius, $color: '') {
    width: $radius;
    height: $radius;
    border-radius: 50%;
    background-color: $color;
}

@mixin ring($radius, $border-width, $color) {
    width: $radius;
    height: $radius;
    border-radius: 50%;
    border: $border-width solid $color;
}
