.page-adviser {
    .page-content {
        padding-top: 0;
        overflow: hidden;
    }

    .adviser__fluid-banner {

        @include breakpoint(not-mobile) {
            -webkit-clip-path: ellipse(156% 80% at 50% 20%);
            clip-path: ellipse(156% 80% at 50% 20%);
        }
    
        @include breakpoint(desktop) {
            min-height: 850px;
            height: 90vh;
            max-height: 1080px;
        }
    
        .btn {
            min-width: 210px;
    
            &:first-of-type {
                margin-right: 10px;
            }
        }
    
        .fluid-banner__wrapper {
            @include breakpoint(mobile) {
                &:before {
                    width: 470px;
                    height: 470px;
                    left: 110px;
                    right: auto;
                    top: 0px;
                    content: '';
                    display: block;
                    border-radius: 50%;
                    background-color: rgba(225, 234, 248, .1);
                    box-shadow: 0 3px 117px 0 rgba(0, 0, 0, .12);
                    position: absolute;
                    z-index: 1;
                }
            }
        }
    
        .bubble {
            @include breakpoint(not-mobile) {
                position: relative;
                z-index: 1;
            }
            @include breakpoint(mobile) {
                display: none;
            }
            &:before {
                content: '';
                display: block;
                @include circle(655px, rgba(225, 234, 248, .10));
                box-shadow: 0px 3px 117px 0 rgba(0, 0, 0, .08);
                position: absolute;
                right: calc((100% - #{$wrapper-width}) / 2 + 72px);
    
                @include breakpoint(tablet) {
                    right: $wrapper-padding-tb;
                    width: 375px;
                    height: 375px;
                }
            }
        }
    
        .fluid-banner__images {
            margin-top: 100px;
            overflow: hidden;
    
            @include breakpoint(desktop) {
                width: calc(100% - 600px);
            }
    
            @include breakpoint(tablet) {
                width: calc(100% - 400px);
                margin-top: 60px;
            }
    
            @include breakpoint(mobile) {
                background-color: $color--blue-2;
                padding-top: 45px;
                margin-top: 0;
                padding-left: $wrapper-padding-mb;
            }
        }
    
        .fluid-banner__img.slick-slide {
            text-align: left;
    
            img {
                max-width: none;
                display: inline-block;
                max-width: 830px;
    
                @include breakpoint(tablet) {
                    max-width: 145%;
                }
    
                @include breakpoint(mobile) {
                    max-width: 120%;
                }
            }
        }
    
        .scroll {
            position: fixed;
            bottom: 60px;
            left: calc((100% - #{$wrapper-width}) / 2);
            z-index: 1;
            text-decoration: none;
            font-weight: bold;
            color: $color--blue-2;
    
            &.absolute {
                position: absolute;
            }
    
            @include breakpoint(tablet) {
                bottom: 50px;
                left: $wrapper-padding-tb;
            }
    
            @include breakpoint(mobile) {
                display: none;
            }
    
            .vertical-text {
                @media (min-width: 1200px) and (max-height: 800px) {
                    display: none;
                }
    
                @media (min-width: 768px) and (max-width: 1199px) and (max-height: 600px) {
                    display: none;
                }
            }
        }
    
        .slick-dots {
            @include breakpoint(mobile) {
                padding-top: 30px;
                padding-bottom: 30px;
                z-index: 1;
                position: relative;
            }
        }
    
    }
    
    .product-banner {
        text-align: left;
    
        .wrapper {
            @include breakpoint(mobile) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    
        .page-section__heading {
            text-align: center;
            margin-bottom: 50px;
    
            @include breakpoint(tablet) {
                margin-bottom: 60px;
            }
    
            @include breakpoint(mobile) {
                margin-bottom: 50px;
            }
        }
    
        .page-section__desc {
            text-align: center;
            max-width: 810px;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 100px;
    
            @include breakpoint(not-mobile) {
                @include font-size(20px, 29px);
            }
    
            @include breakpoint(tablet) {
                max-width: 600px;
                margin-bottom: 75px;
            }
    
            @include breakpoint(mobile) {
                margin-bottom: 55px;
            }
        }
    
        &__row {
    
            @include breakpoint(not-mobile) {
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
            }
        }
    
        .slick-list {
            @include breakpoint(not-mobile) {
                width: 225px;
                margin-right: auto;
                margin-left: auto;
            }
        }
    
        &__col {
            
            &--images {
                flex: 0 0 58.33333%;
                max-width: 58.33333%;
                align-self: center;
                margin-top: 25px;

                @include breakpoint(mobile) {
                    display: none;
                    flex: 0 0 0%;
                    max-width: 0%;
                }
            }
    
            &--details {
                flex: 0 0 41.66667%;
                max-width: 41.66667%;

                @include breakpoint(mobile) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    
        &__title {
            margin-top: 10px;
            margin-bottom: 10px;
    
            @include font-size(20px, 25px);
            font-weight: bold;
            color: $color--blue;
    
            @include breakpoint(tablet) {
                @include font-size(16px, 20px);
                margin-top: 5px;
            }
    
            @include breakpoint(mobile) {
                text-align: center;
                @include font-size(18px, 24px);
                width: 100%;
            }
        }
    
        &__desc {
            margin-top: 0;
            margin-bottom: 10px;
    
            @include font(montserrat, regular);
    
            @include breakpoint(mobile) {
                @include font-size(14px, 19px);
                margin-top: 0;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
    
            .slick-center & {
                @include breakpoint(mobile) {
                    opacity: 1;
                    transition: opacity $transition-time;
                }
            }
        }
    
        .detail-button {
            padding-left: 40px;
            border-left: 3px solid transparent;
            min-height: 80px;
            text-align: left;
            background: none;
            //cursor: pointer;
            cursor: default;

            @include breakpoint(mobile) {
                width: 100%;
                padding-left: 0px;
            }
        }
    
        /*
        .detail-button:hover {
            border-left-color: #f1f1f1;
        }
    
        .detail-button:focus {
            outline: none;
            border-left-color: $color-button-menu-bg;
        }
        */
    
        .slick-arrow {
            position: absolute;
            top: 10px;
            padding: 0;
            width: 49px;
            height: 106px;
            border-radius: 100% / 50%;
            background-color: $color--hawkes-blue;
            font-size: 0;
            z-index: z-index(slider);
    
            &:before {
                font-size: 33px;
                color: $color-btn-purple;
            }
    
            &:hover {
                background-color: darken($color--hawkes-blue, 10%);
            }
    
            &.slick-disabled {
                background-color: #e1e1e1;
                cursor: default;
    
                &:before {
                    color: $color--white;
                }
    
                &:focus {
                    box-shadow: none;
                }
            }
        }
    
        .slick-prev {
            left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @extend %icon, %icon-arrow-left;
            text-align: left;
    
            &:before {
                margin-left: 8px;
            }
    
        }
    
        .slick-next {
            right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            @extend %icon, %icon-arrow-right;
            text-align: right;
    
            &:before {
                margin-right: 8px;
            }
        }
    }
    
    .detail-banner {
        background-color: $color--free-speech-blue;
        text-align: center;
    
        .page-section__heading {
            text-align: center;
            margin-bottom: 50px;
            color: #fff;
    
            @include breakpoint(tablet) {
                margin-bottom: 60px;
            }
    
            @include breakpoint(mobile) {
                margin-bottom: 30px;
            }
        }
    
        @include breakpoint(mobile) {
            padding-top: 35px;
        }
    
        &__title {
            @include font-size(28px, 34px);
            @include font(montserrat, bold);
            margin-top: 0;
            margin-bottom: 45px;
            color: #fff;
    
            @include breakpoint(tablet) {
                font-size: 26px;
            }
    
            @include breakpoint(mobile) {
                margin-bottom: 15px;
                font-size: 24px;
                line-height: 24px;
            }
        }
    
        &__desc {
            margin-top: 0;
            margin-bottom: 0;
            @include font(montserrat, regular);
            color: #fff;
            font-weight: 500;
            text-align: justify;
    
            @include breakpoint(mobile) {
                @include font-size(14px, 19px);
            }
        }
    }
    
    .adviser-pricing-banner {
    
        text-align: center;

        .page-section__heading {
            text-align: center;
            margin-bottom: 40px;
    
            @include breakpoint(tablet) {
                margin-bottom: 60px;
            }
    
            @include breakpoint(mobile) {
                margin-bottom: 50px;
            }
        }
    
        .page-section__desc {
            text-align: center;
            max-width: 810px;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 50px;
    
            @include breakpoint(not-mobile) {
                @include font-size(20px, 29px);
            }
    
            @include breakpoint(tablet) {
                max-width: 600px;
                margin-bottom: 75px;
            }
    
            @include breakpoint(mobile) {
                margin-bottom: 55px;
            }
        }
    
        .wrapper {
            @include breakpoint(mobile) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    
        &__cards {
            display: flex;
            flex-flow: row wrap;
            box-sizing: border-box;
            text-align: center;
    
            @include breakpoint(tablet) {
                display: block;
            }
    
            @include breakpoint(mobile) {
                display: block;
            }
        }
    
        &__card {
            padding: 3rem;
            flex-flow: row wrap;
            margin: 15px;
            max-width: 560px;
            overflow: hidden;
            transition-property: color,background-color,box-shadow,transform;
            transition-duration: .15s;
            color: #3e4543;
            background: $color--white;
            border-radius: 0px;
            box-shadow: 0 -1px 5px rgba(0,0,0,0.05), 0 2px 5px rgba(0,0,0,0.07), 0px 5px 10px rgba(16,70,81,0.2);
    
    
            @include breakpoint(desktop) {
                flex: 1;
            }
    
            @include breakpoint(tablet) {
                margin: 15px auto;
                text-align: center;
                overflow: hidden;
            }
    
            @include breakpoint(mobile) {
                margin: 15px auto;
                text-align: center;
                overflow: hidden;
            }
    
            h4 {
                margin-bottom: 30px;
                margin-top: 30px;
                font-weight: 600;
                font-size: 16px;
                color: #3e4543;
    
                @include breakpoint(tablet) {
                    font-size: 15px;
                    margin-top: 25px;
                }
    
                @include breakpoint(mobile) {
                    font-size: 14px;
                    margin-top: 20px;
                }
            }
    
            ul {
                margin-bottom: 10px;
    
                @include breakpoint(tablet) {
                    margin-bottom: 25px;
                }
    
                @include breakpoint(mobile) {
    
                }
            }
    
            li {
                @include font-size(13px, 18px);
                text-align: left;
                font-weight: 200;
                list-style-type: none;
                list-style-position: outside;
                display: list-item;
                margin-bottom: 7px;
                text-indent: -12px;
    
                &:before {
                    color: $color--light-green;
                    content: "\2714";
                    position: relative;
                    left: -8px;
                    font-size: 16px;
                }
    
                @include breakpoint(tablet) {
                    @include font-size(12px, 15px);
                }
    
                @include breakpoint(mobile) {
                    @include font-size(11px, 14px);
                }
            }

            .btn {
                margin-top: 40px;
            }
        }

        &__title {
            @include font-size(20px, 22px);
            @include font(montserrat, bold);
            margin-top:10px;
            margin-bottom: 30px;
            align-self: center;
    
            @include breakpoint(tablet) {
                font-size: 18px;
                margin-bottom: 25px;
            }
    
            @include breakpoint(mobile) {
                font-size: 18px;
                margin-bottom: 25px;
            }
        }

        &__info {
            @include font-size(14px, 22px);
            font-weight: 400;
            padding-top: 0px;
            padding-bottom: 0px;
            text-align: center;
    
            @include breakpoint(tablet) {
                @include font-size(13px, 18px);
            }
    
            @include breakpoint(mobile) {
                @include font-size(12px, 18px);
            }
        }
    }
}