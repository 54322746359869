/*
==========================================================================
   #Variables
========================================================================== */

/*
 * Button menu
 */
$btn-menu-size: 66px;
$btn-menu-mb-size: 56px;

/*
 * Drop-down arrow transition time
 */
$arrow-transition-time: 0.3s;

/*
 * wrapper
 */
$wrapper-width: 1180px;
$wrapper-padding: 10px;
$wrapper-padding-tb: 40px;
$wrapper-padding-mb: 20px;

/*
 * dark background on the right hand side
 */
$dark-bcg-inside-wrapper-width: 300px;
$dark-bcg-width-tb: 270px;
$dark-bcg-start-point : calc((100vw - #{$wrapper-width}) / 2 + #{$wrapper-width} - #{$dark-bcg-inside-wrapper-width});
$dark-bcg-start-point-tb : calc((100vw - #{$dark-bcg-width-tb}));


/*
 * Header
 */
$header-height-dk: 169.67px;
$header-height-tb: 123.66px;
$header-height-mb: 89px;
$header-height-dk: 169.67px;


