.alert {
    text-align: center;
    padding: 10px 10px;
    animation: fadeIn 0.7s linear;
    color: $color-white;

    &--success {
        background: $color--light-green;
    }

    &--warning {
        //TODO
    }

    &--error {
        background: $color-red;
    }
}
