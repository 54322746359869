/*
==========================================================================
   #Form elements
========================================================================== */
$input-padding-hr: 25px;
$input-padding-hr-mb: 20px;
/**
 * Placeholder
 */
::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.6;
}

:-moz-placeholder { /* Firefox 18- */
    color: inherit;
    opacity: 0.6;
}

::-moz-placeholder { /* Firefox 19+ */
    color: inherit;
    opacity: 0.6;
}

:-ms-input-placeholder {
    color: inherit;
    opacity: 0.6;
}

/**
 * Label
 */
label {
    color: $color-label-text;
}

/**
 * Text input
 */
%input,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="text"],
input[type="search"],
textarea,
select {
    display: block;
    box-sizing: border-box;
    color: $color-textinput-text;
    background: $color--white;
    border: 0;
    border-radius: 2px;
    padding: 20px $input-padding-hr 5px;
    height: 52px;
    max-width: 100%;
    width: 100%;
    -webkit-appearance: none; /* Remove ios text input inner shadow */
    line-height: (24/16);

    @include breakpoint(mobile) {
        height: 42px;
        padding-right: $input-padding-hr-mb;
        padding-left: $input-padding-hr-mb;
        padding-top: 17px;
        // Keep 16px or more to prevent mobile to zoom-in when input focus
        font-size: 16px;
    }

    &:focus {
        outline: 0;
    }

    &.readonly {
        background-color: $color-disabled-input;
        cursor: default;
    }
}

textarea {
    padding: 20px $input-padding-hr 5px;
    height: 155px;
    resize: vertical;

    @include breakpoint(mobile) {
        height: 160px;
        padding-right: $input-padding-hr-mb;
        padding-left: $input-padding-hr-mb;
        padding-top: 20px;
    }
}

/**
 * Select
 */
// change its colour once selected. To get rid of the blue highlight in IE
select::-ms-value {
    background: transparent;
    color: $color-textinput-text;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    padding-right: 59px + $input-padding-hr;
    background-image: url(../img/select-arrow.svg),
        // Select arrorw divider blue line
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#4538d1" viewBox="0 0 1 1"><rect width="1" height="1"/></svg>');
    background-repeat: no-repeat, repeat-y;
    background-position: right 20px center, right 59px top;
    background-size: 18px auto, 1px 1px;
     border: 1px solid transparent;

    @include breakpoint(mobile) {
        background-size: 16px auto, 1px 1px;
        background-position: right 13px center, right 42px top;
    }

    &:focus {
        border: 1px solid $color-textinput-focus-shadow;
    }
}

// Hide default select arrow in IE
select::-ms-expand {
    display: none;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color-textinput-text;
}
option:not(:checked) {
    color: $color-textinput-text; /* prevent <option>s from becoming transparent as well */
}

/**
* Radio and Checkbox common styling
*/
input[type=radio], input[type=checkbox] {
    position: absolute;
    left: -9999px;
    opacity: 0;

    + label {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    + label:before {
        display: inline-block;
        vertical-align: middle;
        align-self: start;
        flex-shrink: 0;
        content: '';
        width: 31px;
        height: 31px;
        margin-right: 15px;
        background: $color--white;
    }

    &:focus {
        + label:before {
            border: 1px solid $color-textinput-focus-shadow;
            box-sizing: border-box;
        }
    }
}

/**
* Radio
*/
input[type=radio] {
    + label:before {
        border-radius: 50%;
    }

    &:checked + label:before {
        background: $color--blue;
        box-shadow: inset 0 0 0 7px $color--white;
    }
}

/**
* Checkbox
*/
input[type=checkbox] {
    + label:before {
        text-align: center;
        line-height: 31px;
    }
    &:checked + label:before {
        @extend %icon, %icon-tick;
    }

    &:disabled + label:hover {
        cursor: default;
    }

    &:disabled + label:before {
        background-color: $color-disabled-input;
    }
}

/**
 * Buttons
 */
%button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    border: 0;
    margin-top: 20px;

    @include breakpoint(mobile) {
        margin-top: 10px;
    }

    &:focus {
        outline: 0;
    }
}


::-ms-clear,
::-ms-reveal {
    display: none;
    width : 0;
    height: 0;
}
