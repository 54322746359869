@keyframes bounce {
    from, 20%, 53%, 80%, to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate(0, 0);
    }

    40%, 43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate(0, -30px);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate(0, -15px);
    }

    90% {
        transform: translate(0, -4px);
    }
}

@keyframes loading {
    0% {
        background-position: -400px 0
    }
    100% {
        background-position: 400px 0
    }
}

// Float up and down
@keyframes float1 {
    0% {
        transform: translate(0%, 0px);
    }
    50% {
        transform: translate(0%, 40px);
    }
    100% {
        transform: translate(0%, 0px);
    }
}

// Float in circular path
@keyframes float2 {
    0% {
        transform: translate(0px, 0px);
    }
    25% {
        transform: translate(10px, 10px);
    }
    50% {
        transform: translate(20px, 0px);
    }
    75% {
        transform: translate(10px, -10px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}

// Float up and down (horizontal centered  bubble)
@keyframes float3 {
    0% {
        transform: translate(-50%, 0px);
    }
    50% {
        transform: translate(-50%, 40px);
    }
    100% {
        transform: translate(-50%, 0px);
    }
}

// Float left to right (centered  bubble)
@keyframes float4 {
    0% {
        transform: translate(-50%, -50%);
    }
    50% {
        transform: translate(-51%, -50%);
    }
    100% {
        transform: translate(-50%, -50%);
    }
}

// Flip in animation
@keyframes flipInX {
    from {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        animation-timing-function: ease-in;
    }

    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }

    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }

    to {
        transform: perspective(400px);
    }
}

.flipInX {
    backface-visibility: visible !important;
    animation-name: flipInX;
    animation-duration: 0.7s;
    animation-fill-mode: both;
}

// Slide in down animation
@keyframes slideInDown {
    from {
        transform: translate3d(0, -50%, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

.slideInDown {
    animation-name: slideInDown;
    animation-duration: 0.7s;
    animation-fill-mode: both;
}

// Set reveal animateion elements visibility to hidden to resolve elements flickering issue
// Only hide them when ScrollReveal is supported
.sr .animate {
    visibility: hidden;
}

// Fade in
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
