.page-contact-thankyou {
    .fluid-banner {
        @include breakpoint(tablet) {
            padding-top: 180px;
        }

        &__detail {
            @include breakpoint(desktop) {
                margin-right: 85px;
            }
        }

        &__desc {
            @include breakpoint(tablet) {
                margin-bottom: 40px;
            }
        }

        &__img {
            @include breakpoint(desktop) {
                flex: 0 0 515px;
                margin-top: -75px;

                img {
                    max-width: 100%;
                    width: 100%;
                }
            }

            @include breakpoint(tablet) {
                align-self: center;
                margin-top: 60px;
            }

            @include breakpoint(tablet-portrait) {
                img {
                    max-width: 495px;
                }
            }

            &:before {
                height: 40%;
            }
        }
    }
}
