ul, ol {
    padding-left: 15px;

    li {
        padding-left: 5px;
        margin-bottom: 5px;

        @include breakpoint(mobile) {
            margin-bottom: 13px;
        }
    }
}
