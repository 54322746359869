/*
==========================================================================
   #Table
========================================================================== */

table {
    width: 1180px;
    border: 1px solid $color-table-border;
    table-layout: auto;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0 0 1em 0;
}

th, td {
    border: 1px solid $color-table-th-border;
    padding: 17px 28px;
}

td {
    @include breakpoint(mobile) {
        padding: 13px 30px;
    }
}

th {
    background: $color-table-th-bg;
    color: $color--white;
    text-align: left;

    @include breakpoint(mobile) {
        padding: 15px 30px;
    }
}
