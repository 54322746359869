/*
==========================================================================
   #Develop
========================================================================== */
body:after{
    // This is needed because mysite.js is using it to get the breakpoint
    // See site.getBreakpoint
    display: none;
}

@include breakpoint(desktop) {
	body:after{
		content: 'Desktop';
	}
}

@include breakpoint(tablet) {
	body:after{
		content: 'Tablet';
	}
}

@include breakpoint(mobile) {
	body:after{
		content: 'mobile';
	}
}
