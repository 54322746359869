/*
==========================================================================
   #Colors
========================================================================== */
// Button menu color
$color-button-menu-bg: #4538d1;

//colors for main menu
$color--main-menu-text: #4538d1;

// Basic colors
$color-white: #ffffff;
$color-gray: #696969;
$color--white: #ffffff;
$color--black: #191d1f;
$color--solitude-blue: #e1eaf8;
$color--free-speech-blue: #4438cf;
$color--hawkes-blue: #c5d8f0;
$color--pale-blue: #c2d9ff;
$color--pale-blue-2: #ddeaff;
$color--light-blue: #6ca5ea;
$color--alice-blue: #f0f5fc;
$color--blue: #4638d0;
$color--blue-2: #4538d1;
$color--blue-3: #cbd8ee;
$color--blue-4: #acc5ed;
$color--violet: #160f71;
$color--dark-violet: #31259f;
$color--violet-2: #3529b7;
$color--violet-3: #4d4766;
$color--aqua: #42e5f8;
$color--aqua-2: #3bcadb;
$color--light-green: #1bafaf;
$color-red: #d23a3a;
$color--ice-blue: #8ebaf2;

// Heading colors
$color-h1: #4d4766;
$color-h2: #1bafaf;
$color-h3: #4638d0;
$color-h4: #160f71;
$color-h5: #6ca5ea;
$color-h6: #6ca5ea;

// Link colors
$color-link-visited: #4538d1;
$color-link-hover: #160f71;
$color-link-focus: #160f71;

// From element colors
$color-form-section-title: #4538d1;
$color-form-section-sub-title: #160f71;
$color-disabled-input: #dae8ff;

// Text input
$color-textinput-text: #4d4766;
$color-textinput-bg: #ffffff;
$color-textinput-hover-bg: #F2F5F6;
$color-textinput-focus-shadow: #66afe9;
$color-select-bg: #ffffff;

// Label colors
$color-label-text: #4538d1;
$color-error-text: #c1474b;

// Button clolors
$color-btn-bg: #4c5357;
$color-btn-text: #ffffff;
$color-btn-visited-text: #ffffff;
$color-btn-hover-text: #ffffff;
$color-btn-hover-bg: #2a2525;
$color-btn-focus-bg: #2a2525;
$color-btn-focus-shadow-1: #ffffff;
$color-btn-focus-shadow-2: #66afe9;
$color-btn-purple: #4638d0;
$color-btn-purple--hover: #31259f;
$color-btn-aqua: #39d4d8;
$color-btn-aqua--hover: #229c9f;
$color-btn-navy: #160f71;
$color-btn-navy--hover: #0a0639;
$color-btn-ice-blue: #6ca5ea;
$color-btn-ice-blue--hover: #5483bd;
$color-btn-violet: #4d4766;
$color-btn-violet--hover: #342f4d;
$color-btn-light-green: #1bafaf;
$color-btn-light-green--hover: #168C8C;

// Table colors
$color-table-border: #4638d0;
$color-table-th-border: #4638d0;
$color-table-td-border: #4638d0;
$color-table-th-bg: #4638d0;
$color-table-tr-bg: #fff;

// Tooltip colors
$color-tooltip-icon: #86a8e0
