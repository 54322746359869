.page-about {
    .page-content {
            padding-top: 0;
            overflow: hidden;
    }
}

.about__fluid-banner {
    padding-bottom: 0;
    @include breakpoint(not-mobile) {
        overflow: hidden;
    }

    .fluid-banner {
        &__wrapper {
            flex-wrap: wrap;
            justify-content: space-between;

            @include breakpoint(desktop) {
                margin-right: calc((100% - 1180px) / 2);
            }

            @include breakpoint(tablet) {
                margin-right: $wrapper-padding-tb;
            }
        }

        &__detail {
            flex: 0 0 700px;
            @include breakpoint(tablet) {
                flex: 0 0 50%;
                margin-bottom: 60px;
            }
        }

        &__desc {
            margin-bottom: 23px;

            @include breakpoint(mobile) {
                 @include font-size(15px, 21px);
                margin-bottom: 13px;
            }
        }

        &__desc--small {
            margin-bottom: 62px;

            @include breakpoint(tablet) {
                margin-bottom: 22px;
            }

            @include breakpoint(mobile) {
                margin-bottom: 22px;
                @include font-size(15px, 21px);
            }
        }

        &__slogan {
            width: 232px;
            color: $color-white;
            margin-top: 125px;
            position: relative;

            @include breakpoint(tablet) {
                width: 200px;
                margin-top: 105px;
            }

            @include breakpoint(mobile) {
                width: 100%;
                margin-top: 35px;
                padding-left: 60px;
                padding-bottom: 30px;
            }

            .heading {
                color: $color-white;
                @include font(lora, bold);
                @include font-size(29px, 35px);
                position: relative;
                z-index: 1;
                margin-top: 32px;

                @include breakpoint(tablet) {
                    font-size: 24px;
                }

                @include breakpoint(mobile) {
                    @include font-size(26px, 34px);
                    width: 195px;
                    margin-top: 14px;
                }

                span {
                    color: $color--aqua;
                }

                p {
                    margin-top: 0;
                }
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: -170px;
                top: -115px;
                @include circle(488px, rgba(225, 234, 248, .10));
                box-shadow: 0px 3px 117px 0 rgba(0, 0, 0, .08);

                @include breakpoint(tablet) {
                    left: -120px;
                    top: -60px;
                    width: 350px;
                    height: 350px;
                }

                @include breakpoint(mobile) {
                    width: 233px;
                    height: 466px;
                    border-bottom-left-radius: 466px;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 466px;
                    border-top-right-radius: 0;
                    right: 0;
                    bottom: -50px;
                    top: auto;
                    left: auto;
                }
            }
        }

        &__img {
            margin-left: auto;
            width: 755px;
            position: relative;

            @include breakpoint(mobile) {
                display: none;
            }
        }
    }

    .icon-bulb {
        @include font-size(53px, 53px);

        @include breakpoint(mobile) {
            @include font-size(40px, 40px);
        }
    }
}

.about-partnerships-banner {
    @include breakpoint(tablet) {
        padding-top: 110px;
        padding-bottom: 90px;
    }

    .page-section__heading {
        text-align: center;
        font-size: 40px;
        margin-bottom: 40px;

        @include breakpoint(mobile) {
            font-size: 25px;
            margin-bottom: 22px;
        }
    }

    .page-section__desc {
        text-align: center;
        max-width: 810px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 125px;

        @include breakpoint(not-mobile) {
            @include font-size(20px, 29px);
        }

        @include breakpoint(tablet) {
            max-width: 600px;
            margin-bottom: 75px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 55px;
        }
    }

    &__row {
        display: flex;
        margin-bottom: 80px;

        @include breakpoint(tablet) {
            margin-bottom: 40px;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
            margin-bottom: 40px;
        }

        &.last {
            margin-bottom: 0;
        }
    }

    &__col {

        &--img {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 500px;
            margin-right: 60px;
            background-color: $color--alice-blue;
            height: 360px;

            @include breakpoint(tablet) {
                flex: 0 0 30%;
                height: 140px;
                margin-right: 30px;
            }

            @include breakpoint(mobile) {
                width: 100%;
                flex: 0 0 190px;
                margin-right: 0;
                margin-bottom: 33px;
            }
        }

        img {
            height: auto;
        }

        &--details {
            @include breakpoint(mobile) {
                text-align: center;
            }
        }
    }

    .item__heading {
        color: $color--blue;
        @include font(montserrant, bold);
        @include font-size(22px, 30px);
        margin-top: 65px;
        margin-bottom: 16px;

        @include breakpoint(tablet) {
            @include font-size(20px, 27px);
            margin-top: 0;
        }

        @include breakpoint(mobile) {
            margin-top: 0;
        }
    }
}

.about-facts-banner {
    background-color: rgb(69, 56, 209);
    position: relative;
    padding-bottom: 95px;

    @include breakpoint(tablet) {
        padding-top: 110px;
    }

    @include breakpoint(mobile) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    &:before {
        content: '';
        display: block;
        border-radius: 50%;
        background-image: linear-gradient(to top, rgb(157, 149, 230) 0%, rgb(255, 255, 255) 54%, rgb(255, 255, 255) 100%);
        box-shadow: 1px 3px 117px 0 rgba(0, 0, 0, .22);
        opacity: 0.11;
        position: absolute;
        right: calc((100% - #{$wrapper-width}) / 2);
        top: -130px;
        width: 278px;
        height: 278px;
        animation: float1 6s ease-in-out infinite;

        @include breakpoint(tablet-portrait) {
            right: -55px;
            top: -110px;
        }

        @include breakpoint(mobile) {
            width: 135px;
            height: 135px;
            right: -45px;
            top: -45px;
        }
    }

    .page-section__heading {
        color: $color--pale-blue;
        text-align: center;
        margin-bottom: 75px;

        @include breakpoint(tablet) {
            font-size: 40px;
            margin-bottom: 80px;
        }

        @include breakpoint(mobile) {
            max-width: 200px;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 30px;
        }
    }

    .slick-track {
        display: flex;
    }

    .slick-list {
        margin-bottom: 40px;
        padding-bottom: 20px !important;

        @include breakpoint(mobile) {
            margin-bottom: 10px;
        }
    }

    &__row {

        &:before {
            content: '';
            display: block;
            border-radius: 50%;
            background-image: linear-gradient(-180deg, rgb(69, 56, 209) 0%, rgb(69, 56, 209) 10%, rgb(255, 255, 255) 80%, rgb(255, 255, 255) 100%);
            opacity: 0.11;
            width: 861px;
            height: 861px;
            position: absolute;
            left: 50%;
            bottom: -200px;
            transform: translateX(-50%);
            animation: float3 6s ease-in-out infinite;

            @include breakpoint(tablet-portrait) {
                left: 200px;
                transform: translateX(0);
               animation: float1 6s ease-in-out infinite;
            }

            @include breakpoint(mobile) {
                width: 555px;
                height: 555px;
                left: 40px;
                transform: translateX(0);
                bottom: -200px;
                animation: float1 6s ease-in-out infinite;
            }
        }

        &:after {
            content: '';
            display: block;
            border-radius: 50%;
            background-image: linear-gradient(-180deg, rgb(69, 56, 209) 0%, rgb(69, 56, 209) 50%, rgb(255, 255, 255) 80%, rgb(255, 255, 255) 100%);
            opacity: 0.2;
            position: absolute;
            width: 453px;
            height: 453px;
            bottom: -65px;
            left: calc((100% - #{$wrapper-width}) / 2);
            animation: float2 6s ease-in-out infinite;

            @include breakpoint(tablet-portrait) {
                left: 0;
            }

            @include breakpoint(mobile) {
                background-image: linear-gradient(to bottom, #ffffff 0%, #ffffff 46%, #4638d0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                width: 293px;
                height: 293px;
                bottom: -65px;
                left: -50px;
                opacity: .6;
            }
        }
    }

    &__col {
        width: 580px;
        background-color: $color-white;
        margin-right: 38px;
        box-shadow: 16.401px 18.868px 0px 0px rgba(0, 0, 0, 0.08);
        height: auto;
        padding: 45px 40px 50px;
        flex-wrap: wrap;
        align-content: flex-start;

        &:not(:first-of-type) {
            display: none;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
            width: calc(100vw - 40px);
            margin-left: 20px;
            margin-right: 20px;
            padding: 35px 30px 20px;
        }

         a {
             word-wrap: break-word;
         }

        .slick-initialized & {
            display: flex;
        }

        .heading {
            color: $color--blue-2;
            @include font(montserrat, black);
            @include font-size(18px, 24px);
            margin-top: 0;
            margin-bottom: 5px;

            @include breakpoint(not-mobile) {
                flex: 0 0 100%;
            }

            @include breakpoint(mobile) {
                font-size: 16px;
            }
        }

        .left, .right {
            width: 50%;

            @include breakpoint(mobile) {
                width: 100%;
            }
        }

        .left {
            // Centering SVG icon added in RichText for Industry Facts
            display: flex;
            align-items: center;

            @include breakpoint(not-mobile) {
                padding-right: 20px;
            }
        }

        .right {
            @include breakpoint(not-mobile) {
                padding-left: 20px;
            }
        }

        .subtitle {
            color: $color--blue-2;
            width: 100%;

            @include breakpoint(mobile) {
                @include font-size(15px, 20px);
                margin-bottom: 5px;
            }

            // Padding out SVG icon added in RichText for Industry Facts
            svg {
                padding: 0 10px;
                display: block;
                margin: auto;
            }
        }

        .desc {
            @include breakpoint(mobile) {
                @include font-size(14px, 19px);
            }
        }
    }

    .slick-dots {
        position: relative;
        top: auto;
        display: flex;
        justify-content: center;

        li {
            padding-left: 13px;
            padding-right: 13px;
            margin-bottom: 0;
        }

        .slick-active {
            button {
                background-color: $color--aqua;
            }
        }
    }

    .slick-arrow {
        position: absolute;
        top: calc(50% - 40px);
        transform: translateY(-50%);
        padding: 0;
        width: 28px;
        height: 60px;
        border-radius: 100% / 50%;
        background-color: $color--hawkes-blue;
        font-size: 0;
        z-index: z-index(slider);

        &:before {
            font-size: 18px;
            color: $color-btn-purple;
        }

        &:hover {
            background-color: darken($color--hawkes-blue, 10%);
        }

        &.slick-disabled {
            background-color: #e1e1e1;
            cursor: default;

            &:before {
                color: $color--white;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }
    .slick-prev {
        left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        @extend %icon, %icon-arrow-left;
        text-align: left;

        &:before {
            margin-left: 5px;
        }

    }

    .slick-next {
        right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @extend %icon, %icon-arrow-right;
        text-align: right;

        &:before {
            margin-right: 5px;
        }
    }
}




