.page-explain {
    .page-content {
            padding-top: 0;
            overflow: hidden;
    }
}

.explain__fluid-banner {
    
    @include breakpoint(not-mobile) {
        overflow: hidden;
    }

    video {
        width: calc(100%);
    }

    .fluid-banner {

        &__wrapper {
            flex-wrap: wrap;
            justify-content: space-between;

            @include breakpoint(desktop) {
                margin-right: calc((100% - 1180px) / 2);
            }

            @include breakpoint(tablet) {
                margin-right: $wrapper-padding-tb;
            }
        }

        &__detail {
            flex: 0 0 700px;
            @include breakpoint(tablet) {
                flex: 0 0 50%;
                margin-bottom: 60px;
            }
        }

        &__desc {
            margin-bottom: 23px;

            @include breakpoint(mobile) {
                 @include font-size(15px, 21px);
                margin-bottom: 13px;
            }

            .transcription {
                font-size: 16px;
                margin-top: 25px;
            }
        }

        &__desc--small {
            font-size: 14px;
        }
    }
}