/*
==========================================================================
   #Buttons
========================================================================== */

$transition-time: 0.2s;
%btn,
.btn {
    display: inline-block;
    max-width: 100%;
    min-width: 228px;
    padding: 16px 40px;
    box-sizing: border-box;
    margin: 0 0 20px 0;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    @include font-size(16px, 20px);
    @include font(montserrat, bold);
    text-transform: uppercase;
    border-radius: 3px;
    transition: color $transition-time, background-color $transition-time;

    @include breakpoint(tablet) {
        @include font-size(14px, 18px);
        padding: 14px 30px;
    }

    @include breakpoint(mobile) {
        width: 100%;
        @include font-size(14px, 14px);
        padding: 14px 20px;
        margin-bottom: 10px;
    }

    &:hover {
        transition: color $transition-time, background-color $transition-time;
    }

    &:focus, &:visited, &:hover {
        color: $color-white;
        text-decoration: none;
    }

    &:active {
        box-shadow: none !important;
    }
}

.btn--mini {
    padding: 9px;
    min-width: 80px !important;
    font-size: 12px;
    margin-top: 1px;
}

.btn--purple {
    background-color: $color-btn-purple;
    color: $color--white;

    &:hover, &:focus {
        background-color: $color-btn-purple--hover;
    }
}

.btn--fat {
    min-width: 180px;
    padding: 16px 50px;
    @include breakpoint(tablet) {
        font-size: 16px;
        line-height: 20px;
    }
    @include breakpoint(mobile) {
        font-size: 14px;
    }
}

.btn--aqua {
    background-color: $color-btn-aqua;
    color: $color--free-speech-blue;

    &:visited {
        color: $color--free-speech-blue;
    }

    &:hover, &:focus {
        background-color: $color-btn-aqua--hover;
        color: $color--white;
    }
}

.btn--navy {
    background-color: $color-btn-navy;
    color: $color--white;

    &:hover, &:focus {
        background-color: $color-btn-navy--hover;
    }
}

.btn--ice-blue {
    background-color: $color-btn-ice-blue;
    color: $color--white;

    &:hover, &:focus {
        background-color: $color-btn-ice-blue--hover;
    }
}

.btn--violet {
    background-color: $color-btn-violet;
    color: $color--white;

    &:hover, &:focus {
        background-color: $color-btn-violet--hover;
    }
}

.btn--light-green {
    background-color: $color-btn-light-green;
    color: $color--white;

    &:hover, &:focus {
        background-color: $color-btn-light-green--hover;
    }
}

.btn--add {
    padding-left: 35px;
    padding-right: 35px;
    @extend %icon, %icon-plus;

    &:before {
        margin-right: 17px;
    }

    @include breakpoint(mobile) {
        display: flex;
        align-items: center;
    }
}

.btn--arrow {
    @include icon(arrow-right, after);
    position: relative;

    &:after {
        margin-left: 30px;
        line-height: 19px;

        @include breakpoint(mobile) {
            position: absolute;
            right: 20px;
        }
    }
}

.btn--disabled {
    opacity: 0.3;
    cursor: not-allowed;
}