.page-register-interest-thankyou {
    .fluid-banner {
        @include breakpoint(not-mobile) {
            padding-bottom: 0;
        }

        &__heading {

            @include breakpoint(mobile) {
                padding-bottom: 28px;
            }

            .rich-text {
                max-width: 570px;

                @include breakpoint(tablet) {
                    max-width: 458px;
                }
            }
        }

        &__detail {
            @include breakpoint(desktop) {
                flex: 0 0 570px;
            }

            @include breakpoint(tablet) {
                width: 405px;
            }

            @include breakpoint(mobile) {
                margin-top: 10px;
            }

            ul {
                @extend .tick;

                @include breakpoint(not-mobile) {
                    margin-top: 37px;
                }
            }

            li {
                margin-bottom: 20px;
            }
        }

        &__desc {
            @include breakpoint(not-mobile) {
                margin-bottom: 43px;
            }

            @include breakpoint(mobile) {
                margin-bottom: 15px;
            }
        }

        &__img {
            @include breakpoint(desktop) {
            width: calc(100% - 570px);
            }

            @include breakpoint(tablet) {
                margin-left: 0;
                margin-right: 0;
            }

            &:before {
                @include breakpoint(mobile) {
                    height: 100%;
                }
            }

            img {
                @include breakpoint(desktop) {
                    width: 688px;
                    height: 632px;
                }
            }
        }

        &__btns {
            .btn {
                min-width: 188px;

                &:first-of-type {
                    margin-right: 20px;

                    @include breakpoint(mobile) {
                        margin-bottom: 10px;
                    }
                }
            }
        }

    }
}
