$component: 'onboarding-journey-progress';

// DASH LINE MIXIN
// =============================================================================

@mixin dash-line($direction, $length, $thickness) {
    content: '';
    position: absolute;
    z-index: -1;

    @if $direction == 'horizontal' {
        width: $length;
        height: $thickness;
        background-image: linear-gradient(to right, currentColor 50%, transparent 0%);
        background-repeat: repeat-x;
        // background-size: x y
        // x is width of dash
        // y is height of dash
        background-size: 10px 100%;
    }

    @if $direction == 'vertical' {
        width: $thickness;
        height: $length;
        background-image: linear-gradient(to bottom, currentColor 50%, transparent 0%);
        background-repeat: repeat-y;
        // background-size: x y
        // x is width of dash
        // y is height of dash
        background-size: 100% 10px;
    }
}

// HORIZONTAL PROGRESS LAYOUT MIXIN
// =============================================================================

@mixin onboarding-journey-progress-horizontal {
    $item-spacing: 30px;
    $item-spacing-tablet: 20px;

    flex-direction: row;

    // CIRCLE
    .#{$component}-item-circle {
        margin-right: $item-spacing;

        @include breakpoint(tablet) {
            margin-right: $item-spacing-tablet;
        }

        // Dash line between items
        &:after {
            @include dash-line('horizontal', $item-spacing, 3px);
            left: calc(100% + 6px);

            @include breakpoint(tablet) {
                @include dash-line('horizontal', $item-spacing-tablet, 3px);
            }
        }
    }

    // DESC BOX
    .#{$component}-item-desc {
        display: none;
    }

    // LAST ITEM
    .#{$component}-item:last-child {
        .#{$component}-item-circle { margin-right: 0; };
        .#{$component}-item-circle:after { display: none };
    }
}

// VERTICAL PROGRESS LAYOUT MIXIN
// =============================================================================

@mixin onboarding-journey-progress-vertical($item-spacing: 30px) {
    flex-direction: column;
    max-width: 260px;

    // ITEM CONTAINER
    .#{$component}-item {
        display: flex;
        padding-bottom: $item-spacing;
        overflow: hidden;
    }

    // CIRCLE
    .#{$component}-item-circle {
        flex-shrink: 0;
        margin-right: $item-spacing;
        width: 69px;
        height: 69px;

        // Dash line
        &:after {
            @include dash-line('vertical', 600px, 3px);
            top: 100%;
            left: 0;
            right: 0;
            margin: auto;
        }

        &__wrapper {
            padding: 10px;
        }

        &__heading { display: none; }

        &__tick {
            width: 27px;
            height: 27px;
            line-height: 27px;
            font-size: 0.7em;
            margin-top: -3px;
            margin-right: -5px;
        }
    }

    // DESC BOX
    .#{$component}-item-desc {
        display: flex;
        width: 100%;
    }

    // LAST ITEM
    .#{$component}-item:last-child {
        .#{$component}-item-circle { margin-top: 0; margin-right: $item-spacing; };
        .#{$component}-item-circle:after { display: none; };
    }
}

// ONBOARDING JOURNEY PROGRESS STYLE (WITHOUT LAYOUT)
// =============================================================================

.#{$component} {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: #{$component}-item-counter;
    display: flex;
    position: relative;
    z-index: 1;

    &--white {
        color: $color-white;
    }

    // ITEM CONTAINER
    &-item {
        padding: 0;
        margin: 0;
        counter-increment: #{$component}-item-counter;
    }

    // CIRCLE
    &-item-circle {
        position: relative;
        width: 133px;
        height: 133px;
        border-radius: 50%;
        border: 3px solid currentColor;
        @include font-size(16px, 19px);
        text-align: center;
        display: flex;
        align-items: center;
        margin: 0;

        @include breakpoint(tablet) {
            width: 94px;
            height: 94px;
        }

        @include breakpoint(mobile) {
            width: 69px;
            height: 69px;
        }

        .#{$component}-item--completed & {
            background-color: currentColor;

            &__wrapper {
                color: $color-white;

                .#{$component}--white & {
                    color: $color--blue;
                }
            }
        }

        .#{$component}-item--current & {
            background-color: $color--light-green;
            border-color: $color--light-green;
        }


        &__wrapper {
            width: 100%;
            padding: 30px 25px;

            @include breakpoint(tablet) {
                padding: 20px;
            }
        }

        &__number {
            @include font(lora, bold);
            font-size: 28px;
            line-height: 0.9;

            &:before {
                content: counter(#{$component}-item-counter);
                line-height: inherit;
            }

            @include breakpoint(tablet) {
                font-size: 19px;
            }
        }

        &__heading {
            font-weight: bold;
            margin-top: 0.4em;

            @include breakpoint(tablet) {
                font-size: 11px;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }

        &__tick {
            @extend %icon-tick;
            width: 36px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 0.8em;
            background-color: $color--light-green;
            border-radius: 50%;
            position: absolute;
            top:  0;
            right: 0;
            color: $color-white;

            @include breakpoint(not-desktop) {
                width: 25px;
                height: 25px;
                line-height: 25px;
                font-size: 0.6em;
            }

            @include breakpoint(mobile) {
                margin-top: -3px;
                margin-right: -6px;
            }
        }

        &__you-here {
            // not include horizontal style of "you here" because no such usecase at current development time
            font-size: 11px;
            line-height: normal;

            &:before {
                @extend %icon-people;
                display: block;
                font-size: 22px;
                margin-bottom: 5px;
            }
        }
    }

    // DESC BOX
    &-item-desc {
        display: flex;
        flex-direction: column;

        &__heading {
            font-weight: bold;
            margin-top: auto;
            margin-bottom: auto;
        }

        &__text {
            font-size: 11px;
            margin-top: 12px;
        }
    }
}

// ONBOARDING JOURNEY PROGRESS HORIZONTAL
// =============================================================================

.#{$component}--horizontal {
    @include onboarding-journey-progress-horizontal;
}

// ONBOARDING JOURNEY PROGRESS VERTICAL
// =============================================================================

.#{$component}--vertical {
    @include onboarding-journey-progress-vertical;
}
