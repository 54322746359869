/*
==========================================================================
   #Default
========================================================================== */
// #High-level base settings
$base-font-size:          16px !default;
$base-font-size-mb:          14px !default;
$base-line-height:        24px !default;
$base-line-height-mb:        19px !default;
$base-text-color:         #4d4766 !default;
$base-background-color:   #fff !default;
